@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    --header-height: 120px;

    @media screen and (max-width: 768px) { --header-height: 100px; }
    @media screen and (max-width: 600px) { --header-height: 70px; }
}


//-------------------
// Variables locales
//-------------------
.header {
    
}


// ----------------
// Header
// ----------------
.header {
    padding-top: 0;
    transition: padding-top 400ms var(--out-cubic);

    &--home {

        .header__container {
            background: transparent;
            position: absolute;
        }

        .header__logo-container {
            position: relative;
            z-index: 10;
        }

        .header__buttons-container {
            position: relative;
            z-index: 10;
        }
    }
    
    &__container {
        width: 100%;
        height: var(--header-height);
        padding-left: var(--lx-120);
        background: rgb(var(--rgb-06));
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: rgba(var(--rgb-14), 0.4);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
        }
    }

    &__logo-container {
        width: 297px;
        height: 69px;

        @media screen and (max-width: 768px) {
            width: 215px;
            height: 50px;
        }
        @media screen and (max-width: 600px) {
            width: 189px;
            height: 44px;
        }
        @media screen and (max-width: 340px) {
            width: 140px;
            height: 33px;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    &__buttons-container {
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__contact-button {
        margin-right: var(--lx-65);
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) { display: none; }
    }

    &__contact-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
            &:not(:first-child) {
                font-weight: 700;
            }

            font-family: var(--ff-1); // Roboto
            font-size: var(--fz-18);
            font-weight: 400;
            line-height: 1.2em;
            letter-spacing: 0.02em;
            color: rgb(var(--rgb-white));
            transition: color 300ms;
        }
    }

    &__contact-button:hover &__contact-text span {
        color: rgba(var(--rgb-white), 0.5);
    }

    &__circle {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgb(var(--rgb-white));
        margin-left: 20px;
        display: block;
        position: relative;

        &::before {
            content: "";
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: rgb(var(--rgb-white));
            transform: scale(1);
            transition: transform 400ms var(--out-cubic);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__contact-button:hover &__circle::before {
        transform: scale(1.1);
    }

    &__svg {

        &--phone {
            width: 20px;
            height: 20px;
            stroke: rgb(var(--rgb-09));
            stroke-width: 1.5px;
            fill: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            @media screen and (max-width: 768px) {
                width: 17px;
                height: 12px;
            }
            @media screen and (max-width: 600px) {
                width: 15px;
                height: 10px;
            }
        }
    }

    &__language-button {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        letter-spacing: 0.02em;
        color: rgb(var(--rgb-white));
        transition: color 300ms;
        margin-right: var(--lx-75);

        &:hover {
            color: rgba(var(--rgb-white), 0.5);
        }

        @media screen and (max-width: 768px) { margin-right: 25px; }
    }

    &__hamburger-button-box {
        width: var(--lx-120);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1100px) { 
            width: auto; 
            padding: 0 25px 0 0;
        }
    }

    &__hamburger-button {
        width: 27px;
        height: 21px;
        opacity: 1;
        transition: opacity 300ms;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        pointer-events: all;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 600px) {
            width: 24px;
            height: 19px;
        }
    }

    &__hamburger-line {
        width: 100%;
        height: 2px;
        background: rgb(var(--rgb-white));
        opacity: 1;
        transition: transform 400ms var(--out-cubic), opacity 300ms;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        
        &:first-child {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
        &:last-child {
            bottom: 0;
        }
    }
}
