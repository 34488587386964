@use "../base/typography";

:root {
    --dynamic__p-font-line-height: 1.6em; //Doit inclure le "em" autrement les bullets des listes ne seront pas alignées

    --dynamic__ol-indent: 50px;
    --dynamic__ol-indent-last: 40px;
    --dynamic__ol-number-color: rgb(var(--rgb-05));
    --dynamic__ul-indent: 20px;
    --dynamic__ul-bullet-color: rgb(var(--rgb-05));

    --dynamic__table-header-background: rgb(var(--rgb-01));
    --dynamic__table-header-color: white;
    --dynamic__table-secondary-header-background: rgb(var(--rgb-04));
    --dynamic__table-secondary-header-color: rgb(var(--rgb-white));

    --dynamic__p-margin: 25px;
    --dynamic__p-margin-under-button: 15px; //Marge sous les boutons si ils tombent sur 2 lignes
    --dynamic__p-margin-besides-button: 15px; //Marge entre les boutons
    --dynamic__p-margin-button: 40px; //Plus grosse marge pour paragraphe contenant des boutons

    @media screen and (max-width: 768px) {
        --dynamic__ol-indent: 25px;
        --dynamic__ol-indent-last: 25px;
    }
}


.dynamic {
    color: rgb(var(--rgb-01));

    > :first-child {
        margin-top: 0 !important;
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    h2 {
        @extend %large-title;
        color: rgb(var(--rgb-01));
        margin-top: 65px;
        margin-bottom: 30px;
    }

    h3 {
        @extend %medium-title;
        color: rgb(var(--rgb-01));
        margin-top: 65px;
        margin-bottom: 30px;
    }

    h4 {
        @extend %small-title;
        color: rgb(var(--rgb-01));
        margin-top: 65px;
        margin-bottom: 30px;
    }

    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6 {
        margin-top: 0;
    }

    .introduction {
        @extend %introduction;
        color: rgb(var(--rgb-01));
    }

    p {
        @extend %paragraph;
        color: rgb(var(--rgb-01));
        margin-top: var(--dynamic__p-margin);
        margin-bottom: var(--dynamic__p-margin);

        //40px
        &.buttonMargin {
            margin-bottom: calc(var(--dynamic__p-margin) - var(--dynamic__p-margin-under-button));
            margin-left: calc(var(--dynamic__p-margin-besides-button) / -2);
            margin-right: calc(var(--dynamic__p-margin-besides-button) / -2);

            .primary-button,
            .secondary-button {
                // Ajouter une marge sous les boutons
                margin-bottom: var(--dynamic__p-margin-under-button);
                margin-left: calc(var(--dynamic__p-margin-besides-button) / 2);
                margin-right: calc(var(--dynamic__p-margin-besides-button) / 2);
            }

            &--first {
                margin-top: var(--dynamic__p-margin-button)
            }

            &--last {
                margin-bottom: calc(var(--dynamic__p-margin-button) - var(--dynamic__p-margin-under-button))
            }
        }
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    sup {
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: -0.3em;
    }

    sub {
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: 0.3em;
    }

    a:not(.primary-button):not(.secondary-button) {
        @extend %paragraph;
        color: rgb(var(--rgb-01));
        text-decoration: none;
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-07));
        transition: box-shadow 0.3s;

        &.link-image,
        &.link-image:hover {
            box-shadow: none;
        }

        &:hover {
            box-shadow: inset 0 -1.1em 0 0 rgb(var(--rgb-07));
        }

        //Icones liens externes et téléchargement
        svg {
            margin-left: 8px;
            width: 11px;
            height: 11px;
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".zip"] {
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    h2,
    h3,
    h4,
    p,
    pre,
    blockquote {
        a:not(.primary-button):not(.secondary-button) {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
        }
    }

    ol,
    ul {
        margin-top: 30px;
        margin-bottom: 30px;

        li {
            &:not(:first-child) {
                margin-top: 25px;
            }

            font-family: var(--ff-1); // Roboto
            font-size: var(--fz-17);
            font-weight: 400;
            line-height: 1.6em;

            ol,
            ul {
                margin-top: 20px;
                margin-bottom: 0;
                width: 100%;

                li {
                    &:not(:first-child) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: li;

        & > li {
            position: relative;
            padding-left: var(--dynamic__ol-indent);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                font-family: var(--ff-1); // Roboto
                font-size: var(--fz-17);
                font-weight: 700;
                color: var(--dynamic__ol-number-color);
                position: absolute;
                display: inline-block;
                left: 0;
                top: calc(var(--dynamic__p-font-line-height) / 2);
                transform: translate(0, -50%);

                @media screen and (max-width: 425px) {
                    font-size: 1.5rem;
                }
            }

            ol > li {

                // <ol> 2e niveau
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {

                    // <ol> 3e niveau
                    &::before {
                        content: counter(li, lower-roman) ".";
                    }

                    padding-left: var(--dynamic__ol-indent-last);
                }
            }
        }
    }

    ul {
        & > li {
            padding-left: var(--dynamic__ul-indent);
            position: relative;

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                border: 1px solid var(--dynamic__ul-bullet-color);
                background: var(--dynamic__ul-bullet-color);
                position: absolute;
                left: 0;
                transform: translate(0, -50%);
                border-radius: 50%;
                box-sizing: border-box;
                top: calc(var(--dynamic__p-font-line-height) / 2);
            }

            & > ul {
                li {
                    &::before {
                        background: transparent;
                    }

                    & > ul {
                        li {
                            &::before {
                                background: var(--dynamic__ul-bullet-color);
                            }
                        }
                    }
                }
            }
        }
    }

    blockquote {
        position: relative;
        padding-left: 30px;
        margin-top: 35px;
        margin-bottom: 35px;
        border-left: 10px solid rgb(var(--rgb-05));

        p {
            font-family: var(--ff-1); // Roboto
            font-size: var(--fz-30);
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-01));

            @media screen and (max-width: 1024px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 768px) {
                font-size: 1.8rem;
            }

            @media screen and (max-width: 425px) {
                font-size: 1.6rem;
            }
        }
    }

    .emphasis {
        background: rgb(var(--rgb-white));
        margin-top: 30px;
        margin-bottom: 65px;
        padding: 45px 40px;

        & > :first-child {
            margin-top: 0;
        }

        & > :last-child {
            margin-bottom: 0
        }
    }

    .table-wrapper {
        overflow-x: auto;
        margin-top: 30px;
        margin-bottom: 65px;

        table {
            overflow-x: hidden;
            width: 100%;
            min-width: 600px;
            border-collapse: collapse;

            tr {
                background: rgb(var(--rgb-white));
                
                &:nth-child(2n) {
                    background: rgb(var(--rgb-08));
                }

                th,
                td {
                    padding: 20px 35px;
                    line-height: 1.5625em; // 25px/16p
                    font-family: var(--ff-1);
                    font-size: 1.7rem;

                    @media screen and (max-width: 768px) {
                        font-size: 1.6rem;
                    }

                    @media screen and (max-width: 375px) {
                        font-size: 1.5rem;
                    }

                    & > :first-child {
                        margin-top: 0;
                    }

                    & > p,
                    & > pre,
                    & > strong,
                    & > em,
                    & > a,
                    & > span,
                    & > sub,
                    & > sup,
                    & > ul,
                    & > ol {
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }

                th,
                td.header {
                    background-color: var(--dynamic__table-header-background);
                    font-weight: 700;
                    color: var(--dynamic__table-header-color);
                    text-align: left;
                }

                td {
                    &.secondary-header {
                        font-weight: 700;
                        background-color: var(--dynamic__table-secondary-header-background);
                        color: var(--dynamic__table-secondary-header-color);
                    }
                }
            }
        }
    }

    pre {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7em;
        white-space: normal;
        color: rgb(var(--rgb-03));
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .videoWrapper {
        max-width: 560px;

        .ytVideo {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .table-wrapper,
    .emphasis {

        // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
        & + pre {
            margin-top: -45px;
        }

        // Modifier la marge pour les tableaux sous les tableaux
        & + .table-wrapper,
        & + .emphasis {
            margin-top: -35px;
        }
    }


    /* ----- Image - No Caption ----- */

    img {

        position: relative;
        max-width: 100%;

        &.fr-dib {

            /* block */

            display: block;
            margin: 30px auto;
            float: none;
            vertical-align: top;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {

            /* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
            }
        }
    }

    /* ----- Image - With Caption ----- */

    .fr-img-caption {

        img {
            width: 100%;
        }

        &.fr-dib {

            /* block */

            display: block;
            margin: 30px auto;
            float: none;
            vertical-align: middle;
            text-align: center;

            &.fr-fil {
                margin-left: 0;
                text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
                text-align: right;
            }

            .fr-img-wrap {

                .fr-inner {
                    display: block;
                }
            }
        }

        &.fr-dii {

            /* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            text-align: center;

            &.fr-fil {
                float: left;
                margin-left: 0;
                text-align: left;
            }

            &.fr-fir {
                float: right;
                margin-right: 0;
                text-align: right;
            }
        }
    }

    span.fr-fic.oc-img-rounded img,
    img.fr-fic.oc-img-rounded {
        border-radius: 100%;
        background-clip: padding-box;
    }

    span.fr-fic.oc-img-bordered img,
    img.fr-fic.oc-img-bordered {
        border: solid 10px #ccc;
    }

}