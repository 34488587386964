@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.page-title {
    --image-width: 520px;

    @media screen and (max-height: 700px) { --image-width: 420px; }
    @media screen and (max-width: 1024px) { --image-width: 420px; }
    @media screen and (max-width: 900px) { --image-width: 320px; }
}


// ----------------
// Page Title
// ----------------
.page-title {
    position: relative;
    z-index: 10;

    &__container {
        width: 100%;
        background: rgb(var(--rgb-06));
        padding-right: var(--lx-120);
        margin-bottom: var(--ly-110);
        display: flex;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 105px;
            background: rgb(var(--rgb-02));
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @media screen and (max-width: 700px) { 
            padding-left: var(--lx-120);
            margin-bottom: var(--ly-65);
            flex-direction: column;
            align-items: flex-start;

            &::after {
                display: block;
            }
        }

        @media screen and (max-width: 600px) {
            &::after {
                height: 85px;
            }
        }
    }

    &__picture-container {
        width: var(--image-width);
        position: relative;

        @media screen and (max-width: 700px) { 
            width: 100%; 
            order: 2;
            z-index: 5;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__credit {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 400;
        color: rgb(var(--rgb-white));
        background: rgba(var(--rgb-01), 0.5);
        padding: 13px 25px;
        position: absolute;
        right: 0;
        bottom: 0;

        @media screen and (max-width: 768px) { padding: 15px; }
    }

    &__text-block {
        width: calc(100% - var(--image-width));
        padding-top: var(--ly-140);
        padding-bottom: var(--sy-30);
        padding-left: var(--lx-80);

        @media screen and (max-width: 700px) { 
            width: 100%;
            padding-top: 0;
            padding-bottom: 50px;
            padding-left: 0;
            order: 1; 
        }
    }

    &__surtitle {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        line-height: 1.2em;
        letter-spacing: 0.05em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: 25px;

        @media screen and (max-width: 700px) { margin-bottom: 10px; }
    }

    &__date {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-04));
        margin-bottom: 20px;
    }

    &__title {
        @extend %huge-title;
        text-transform: uppercase;
        color: rgb(var(--rgb-white));
        margin-left: -3px;
    }

    .span-container {
        font-size: 0;
        display: inline-block;
        overflow: hidden;
    }

    .span-text {
        display: inline-block;
        font-size: var(--fz-85);
    }

    .span-space {
        font-family: initial;
        font-size: calc(var(--fz-85) / 1.92);
        line-height: 0;
        display: inline-block;
    }

    &__category {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        margin-top: 20px;
    }

    &__select {
        width: 270px;

        @media screen and (max-width: 400px) { width: 100%; }

        .select-field {
            .select-label {
                &:hover {
                    background: rgb(var(--rgb-04));
                }
            }

            .dropdown-option {
                &:hover {
                    background: rgba(var(--rgb-04), 0.1);
                    color: rgb(var(--rgb-01));
                }
        
                &.selected {
                    background: rgba(var(--rgb-04), 0.1);
                    color: rgb(var(--rgb-01));
                }
            }

            .tail-select.active {
                .select-label {
                    background: rgb(var(--rgb-04));
                }
            }
        }

    }

    &__btn-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }

    &__btn {
        width: var(--lx-120);
        padding-top: 34px;
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: var(--lx-120);
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;
        position: absolute;
        right: 0;
        bottom: 50px;
        will-change: transform;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 768px) { display: none; }
    }

    &__svg {
        position: relative;

        &--arrow {
            width: 13px;
            height: 14px;
            stroke-width: 1.5px;
            stroke: rgb(var(--rgb-white));
            fill: none;
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(50%) rotate(-90deg);

            animation-name: bouncing;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;
        }

        @keyframes bouncing {
            0% { top: 0; }
            50% { top: 10px; }
            100% { top: 0; }
        }
    }

    &--no-image {
        .page-title__container {
            padding-left: 0;
            
            &::after {
                display: none;
            }
        }

        .page-title__text-block {
            padding-top: var(--ly-40);
            padding-bottom: var(--ly-140);
            padding-left: var(--lx-120);

            @media screen and (max-width: 500px) {
                padding-top:0;
                padding-bottom: 40px;
            }
        }
    }

    &--items-over {
        .page-title__container {
            margin-bottom: 0;
        }

        .page-title__text-block {
            padding-bottom: var(--ly-120);

            @media screen and (max-width: 500px) {
                padding-top:0;
                padding-bottom: 40px;
            }
        }
    }
}

.page--news-list {
    .page-title {

        &__text-block {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media screen and (max-width: 700px) {
                flex-direction: column;
                justify-content: initial;
            }
        }

        &__title {
            @media screen and (max-width: 700px) { margin-bottom: 30px; }
        }
    }
}

.page--news-detail {
    .page-title {
        &__text-block {
            padding-top: 65px;
        }
    }
}

.page

.page-title-contact {
    position: relative;
    z-index: 10;

    &__container {
        width: 100%;
        background: rgb(var(--rgb-06));
        padding-right: var(--lx-120);
        padding-left: var(--lx-120);
        margin-bottom: var(--ly-110);
        display: flex;
        justify-content: space-between;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 105px;
            background: rgb(var(--rgb-02));
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @media screen and (max-width: 900px) { padding-left: var(--lx-120); }

        @media screen and (max-width: 800px) { 
            flex-direction: column;
            align-items: flex-start;

            &::after {
                display: block;
            }
        }

        @media screen and (max-width: 600px) {
            &::after {
                height: 85px;
            }
        }
    }

    &__picture-container {
        width: 601px;
        margin-left: 30px;

        @media screen and (max-height: 700px) { width: 401px; }
        @media screen and (max-width: 800px) { 
            width: 100%; 
            margin-left: 0;
            position: relative;
            z-index: 5;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 122%;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        padding-bottom: 50px;
        position: relative;
        z-index: 5;

        .breadcrumb {
            
            .breadcrumb__container {
                padding-right: 0;

                @media screen and (max-width: 800px) { padding-right: var(--lx-120); }
            }
        }

        @media screen and (max-width: 800px) { width: 100%; }
    }

    &__text-block-items {
        margin-top: 20px;

        @media screen and (max-width: 1024px) { margin-top: 10px; }

        @media screen and (max-width: 900px) { margin-top: 0; }
    }

    &__title {
        @extend %huge-title;
        text-transform: uppercase;
        color: rgb(var(--rgb-white));
    }

    .span-container {
        font-size: 0;
        display: inline-flex;
        overflow: hidden;
    }

    .span-text {
        display: inline-block;
        font-size: var(--fz-85);
    }

    .span-space {
        font-family: initial;
        font-size: calc(var(--fz-85) / 1.92);
        line-height: 0;
        display: inline-block;
    }

    &__blue-box {
        background: rgb(var(--rgb-04));
        border-radius: 4px;
        padding: 35px;
        margin: 25px 0 45px;
        display: inline-block;

        @media screen and (max-width: 1024px) { padding: 30px; }

        @media screen and (max-width: 900px) { padding: 25px; }
    }

    &__blue-box-title {
        font-family: var(--ff-2); // Tungtsen
        font-size: var(--fz-40);
        font-weight: 600;
        line-height: 1em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
    }

    &__blue-box-text {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-17);
        font-weight: 400;
        line-height: 1.3em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
    }

    &__phone-and-email {
        margin-bottom: 30px;
    }

    &__phone {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-40);
        font-weight: 700;
        line-height: 1.2em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;
        margin-bottom: 30px;
        display: block;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 1024px) { margin-bottom: 20px; }

        @media screen and (max-width: 900px) { margin-bottom: 10px; }
    }

    &__email {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-20);
        font-weight: 400;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        
        span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-07));
            transition: box-shadow 0.3s;
        }

        &:hover {
            span {
                box-shadow: inset 0 -1.3em 0 0 rgb(var(--rgb-07));
            }
        }
    }

    &__address-item {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-20);
        font-weight: 400;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__share-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__share {
        pointer-events: all;
        position: absolute;
        top: calc(var(--breacrumb-height) / 2);
        right: calc(var(--lx-120) / 2);
        transform: translate(50%,-50%);
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 800px) { display: none; }
    }

    &__btn {
        width: var(--lx-120);
        padding-top: 34px;
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: var(--lx-120);
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;
        position: absolute;
        right: 0;
        bottom: 50px;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 800px) { display: none; }
    }

    &__svg {
        position: relative;

        &--arrow {
            width: 13px;
            height: 14px;
            stroke-width: 1.5px;
            stroke: rgb(var(--rgb-white));
            fill: none;
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(50%) rotate(-90deg);

            animation-name: bouncing;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;
        }

        @keyframes bouncing {
            0% { top: 0; }
            50% { top: 10px; }
            100% { top: 0; }
        }

        &--share {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-white));
            fill: rgb(var(--rgb-white));
            position: relative;
        }
    }
}

// Animation du titre
.page-title,
.page-title-contact {
    .span-text,
    .span-space {
        transform: translateY(100%);
        transition-property: transform;
        transition-duration: 1300ms;
        transition-timing-function: var(--out-cubic);

        &[data-fire] {
            transform: none;
        }
    }
}

.js-scrollfire-delay-enabled .page-title,
.js-scrollfire-delay-enabled .page-title-contact {
    .span-text,
    .span-space{
        transition-delay: 400ms;
    }
}