:root {
    --select-field__max-width: 500px;

    --select-field__label-font-size: 1.5rem;
    --select-field__label-color: rgb(var(--rgb-01));
    --select-field__label-color-hover: rgb(var(--rgb-white));
    --select-field__label-color-active: rgb(var(--rgb-white));

    --select-field__label-background: rgb(var(--rgb-white));
    --select-field__label-background-active: rgb(var(--rgb-01));
    --select-field__label-background-hover: rgb(var(--rgb-01));

    --select-field__label-pointer-color: rgb(var(--rgb-05));
    --select-field__label-pointer-color-hover: rgb(var(--rgb-white));
    --select-field__label-pointer-color-active: rgb(var(--rgb-white));

    --select-field__option-color: rgb(var(--rgb-01));
    --select-field__option-color-hover: rgb(var(--rgb-white));
    --select-field__option-color-selected: rgb(var(--rgb-white));

    --select-field__option-background: rgb(var(--rgb-white));
    --select-field__option-background-hover: rgb(var(--rgb-04));
    --select-field__option-background-selected: rgb(var(--rgb-04));

    --select-field__scrollbar-color: rgba(var(--rgb-01), 0.5);
    --select-field__scrollbar-background-color: transparent; //Tu peux mettre "transparent" ici
}

.select-field {

    .tail-select {
        position: relative;
        max-width: var(--select-field__max-width);

        svg {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            right: 25px;
            transform: translateY(-50%) rotate(90deg);
            transition: transform 500ms, stroke 500ms;
            stroke: var(--select-field__label-pointer-color);
            stroke-width: 2px;
            fill: none;
            z-index: 1;
            pointer-events: none;

            @media screen and (max-width: 600px) { right: 30px; }
        }

        &:hover {
            svg {
                stroke: var(--select-field__label-pointer-color-hover);
            }
        }
    }

    .select-label {
        padding: 21px 25px;
        border-radius: 4px;
        border: 0;
        background: var(--select-field__label-background);
        transition: background 500ms, border-color 500ms, border-radius 300ms var(--out-cubic);
        cursor: pointer;
        user-select: none;
        position: relative;
        z-index: 1;

        &:hover {
            background: var(--select-field__label-background-hover);
        }

        @media screen and (max-width: 600px) { padding: 30px 50px 30px 30px; }
    }

    .label-inner {
        display: block;
        position: relative;
        font-size: var(--select-field__label-font-size);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--select-field__label-color);
        transition: color 300ms;
    }

    .select-label:hover .label-inner {
        color: var(--select-field__label-color-hover);
    }
    
    .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        border-top: 1px solid var(--color8);
        z-index: 2;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms var(--out-cubic), opacity 500ms var(--out-cubic), visibility 0ms ease 500ms;
        -webkit-box-shadow: 0px 3px 20px rgba(0,0,0,.05);
        -moz-box-shadow: 0px 3px 20px rgba(0,0,0,.05);
        box-shadow: 0px 3px 20px rgba(0,0,0,.05);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .dropdown-inner {
        overflow: auto;
        max-height: 195px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
            height: 5px;
            background-color: var(--select-field__scrollbar-background-color);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--select-field__scrollbar-color);
        }
    }

    .dropdown-option {
        padding: 21px 25px;
        font-family: var(--select-field__option-font-family);
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.5em;
        color: var(--select-field__option-color);
        background: var(--select-field__option-background);
        cursor: pointer;
        transition: background 300ms, color 300ms;
        user-select: none;

        &:hover {
            color: var(--select-field__option-color-hover);
            background: var(--select-field__option-background-hover);
        }

        &.selected {
            color: var(--select-field__option-color-selected);
            background: var(--select-field__option-background-selected);
        }
    }

    // Select natif
    select {
        width: 100%;
        padding: 21px 25px;
        border-radius: 4px;  
        font-size: var(--select-field__label-font-size);
        font-weight: 600;
        line-height: 1.5em;
        text-transform: var(--select-field__label-text-transform);
        letter-spacing: var(--select-field__label-letter-spacing);
        color: var(--select-field__label-color);
        border: 0;
        outline: none;
        -webkit-appearance: none;
        background: url("../medias/images/icons/arrow-select.svg") no-repeat calc(100% - 25px) 50% / 14px 14px var(--select-field__label-background);
        max-width: var(--select-field__max-width);

        // Permet de pouvoir scroller au select si le champs est requis et vide
        &[data-select-hidden="0"] {
            display: block !important;
            height: 0;
            opacity: 0;
            padding: 0;
            margin: 0;
        }
    }

    select::-ms-expand {
        display: none;
    }

    .tail-select.active {

        svg {
            transform: translateY(-50%) rotate(270deg);
            stroke: var(--select-field__label-pointer-color-active);
        }

        .select-label {
            background: var(--select-field__label-background-active);
            border-radius: 4px 4px 0 0;
        }

        .label-inner {
            color: var(--select-field__label-color-active);
        }

        .select-dropdown {
            opacity: 1;
            top: 100%;
            visibility: visible;
            pointer-events: all;
            transition: top 500ms var(--out-cubic), opacity 500ms var(--out-cubic);
        }
    }

    &__error {
        position: absolute;
    }
}
