@use "../../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.overlay-menu {
    --image-dimensions: 654px;

    @media screen and (max-height: 850px) { --image-dimensions: 604px; }
    @media screen and (max-height: 800px) { --image-dimensions: 554px; }
    @media screen and (max-height: 750px) { --image-dimensions: 504px; }
    @media screen and (max-height: 700px) { --image-dimensions: 454px; }
    @media screen and (max-height: 650px) { --image-dimensions: 404px; }
    @media screen and (max-height: 600px) { --image-dimensions: 354px; }
    @media screen and (max-height: 550px) { --image-dimensions: 304px; }
    @media screen and (max-height: 500px) { --image-dimensions: 254px; }

    @media screen and (max-width: 1400px) { --image-dimensions: 624px; }
    @media screen and (max-width: 1300px) { --image-dimensions: 594px; }
    @media screen and (max-width: 1200px) { --image-dimensions: 564px; }
    @media screen and (max-width: 1100px) { --image-dimensions: 534px; }
    @media screen and (max-width: 1000px) { --image-dimensions: 504px; }
    @media screen and (max-width: 900px) { --image-dimensions: 474px; }
    @media screen and (max-width: 800px) { --image-dimensions: 444px; }
}


// ----------------
// Overlay | Menu
// ----------------
.overlay-menu {
    pointer-events: none;
    position: absolute;
    top: var(--header-height);
    transition: top 400ms var(--out-cubic);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;

    &__container {
        width: 100%;
        height: 0;
        background: rgb(var(--rgb-01));
        opacity: 0;
        transition: height 700ms var(--out-cubic), opacity 300ms;
        pointer-events: none;
        display: flex;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    &__left {
        width: calc(100% - var(--image-dimensions));
        height: 100%;
        padding: var(--ly-120) 25px var(--ly-120) var(--lx-120);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none; // Enlève la barre de défilement

        &::-webkit-scrollbar { display: none; } // Enlève la barre de défilement 

        @media screen and (max-width: 768px) { 
            width: 100%; 
            padding: var(--ly-120) var(--lx-120);
        }
    }

    &__links {
        margin-bottom: var(--ly-80);
        margin-left: -1px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__link {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-70);
        font-weight: 600;
        line-height: 1.1em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: color 300ms, opacity 600ms;
        text-transform: uppercase;
        margin-bottom: 20px;
        position: relative;
        z-index: 5;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: rgb(var(--rgb-04));
        }
    }

    .span-container {
        display: inline-block;
        overflow: hidden;
        pointer-events: none;
    }

    .span-text {
        display: inline-block;
        font-size: var(--fz-70);
    }

    .span-space {
        font-family: initial;
        font-size: calc(var(--fz-70) / 1.92);
        line-height: 0;
        display: inline-block;
    }

    .span-text,
    .span-space {
        transform: translateY(calc(100% + 5px));
        transition-property: transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-cubic);
        transition-delay: 700ms;
    }

    &__tiny-links {
        display: flex;
        position: relative;
        z-index: 5;
    }

    &__tiny-links-left,
    &__tiny-links-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__tiny-links-left {
        margin-right: var(--lx-100);
    }

    &__tiny-link {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            p {
                opacity: 0.5;
                transition: opacity 300ms;
            }
        }
    }

    &__right {
        width: var(--image-dimensions);
        height: 100%;
        padding-top: var(--ly-120);
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 768px) { display: none; }
    }

    &__picture-container {
        width: 100%;
        height: var(--image-dimensions);
        position: relative;
    }

    &__picture {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 100%;
        opacity: 0;
        transition: opacity 300ms;

        &:not(:first-child) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }


    // Classe ajouté par la function changeImg()
    .visible-image {
        opacity: 1;
    }


    // Animations des éléments du contenu
    &__tiny-link,
    &__picture-container {
        opacity: 0;
        transition: opacity 600ms;
    }
}

.show-overlay-menu {

    .overlay-menu {

        &__container {
            height: calc((var(--vh, 1vh) * 100) - var(--header-height));
            opacity: 1;
            pointer-events: all;
        }

        .span-text,
        .span-space {
            transform: none;
        }

        &__tiny-link,
        &__picture-container {
            opacity: 1;
        }

        &__tiny-link {
            transition-delay: 1000ms;
        }
    
        &__picture-container {
            transition-delay: 1400ms;
        }
    }

    .header {
        &__hamburger-line {
            &:first-child {
                width: 0;
                opacity: 0;
            }
            &:nth-child(2) {
                transform: translateY(-50%) translateX(-50%) rotate(45deg);
            }
            &:nth-child(3) {
                transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            }
            &:last-child {
                width: 0;
                opacity: 0;
            }
        }
    }

    .home-banner {
        &__container {
            &::after {
                height: calc((var(--vh, 1vh) * 100) - var(--header-height));
            }
        }
    }
}

.show-overlay-menu.closing-overlay-menu {
    .overlay-menu {
        
        &__container {
            height: 0;
            transition-delay: 700ms;
            opacity: 0;
            pointer-events: none;
        }

        .span-text,
        .span-space {
            transform: translateY(calc(100% + 5px));
            transition-delay: 300ms;
        }

        &__link,
        &__tiny-link,
        &__picture-container {
            opacity: 0;
            transition-duration: 400ms;
            transition-delay: 0ms;
        }
    }

    .header {
        &__hamburger-button {
            pointer-events: none;
        }

        &__hamburger-line {
            &:first-child {
                width: 100%;
                opacity: 1;
            }
            &:nth-child(2) {
                transform: translateY(-50%) translateX(-50%) rotate(0deg);
            }
            &:nth-child(3) {
                transform: translateY(-50%) translateX(-50%) rotate(0deg);
            }
            &:last-child {
                width: 100%;
                opacity: 1;
            }
        }
    }

    .home-banner {
        &__container {
            &::after {
                height: 0;
                transition-delay: 700ms;
            }
        }
    }
}