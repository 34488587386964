.gallery {
    --gallery-height: 400px;
    @media screen and (max-width: 768px) { --gallery-height: 335px; }
    @media screen and (max-width: 500px) { --gallery-height: 200px; }
    --gallery-margin: 20px;
    @media screen and (max-width: 768px) { --gallery-margin: 15px; }
    @media screen and (max-width: 500px) { --gallery-margin: 10px; }
}

.gallery {
    display: flex;
    flex-flow: row nowrap;
    max-width: 920px;
    height: var(--gallery-height);

    &__left {
        flex: 1;
        margin-right: var(--gallery-margin);
    }

    &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: calc((var(--gallery-height) - var(--gallery-margin)) / 2 );
    }

    &__block {
        display: none;
        position: relative;
        height: 100%;
        
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgb(var(--rgb-06));
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;
        }

        &:hover::before {
            opacity: 0.4;
        }
    }

    &__link {
        display: block;
        height: 100%;
        outline: none;
    }

    &__block-1,
    &__block-2,
    &__block-3 {
        display: block;
    }

    &__block-1 &__link,
    &__block-2 &__link,
    &__block-3 &__link {
        display: block;
        height: 100%;
    }

    &__block-2,
    &__block-3 {
        height: calc( (var(--gallery-height) - var(--gallery-margin)) / 2 );
    }

    &__block-3::before {
        opacity: 0.6;
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__plus {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            top: 50%;
            left: 50%;
            background-color: rgb(var(--rgb-white));
            transform: translate(-50%,-50%) rotate(0deg);
        }

        &::after {
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }
}