@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.pagination {
    
}


// ----------------
// Pagination
// ----------------
.pagination {
    margin-bottom: var(--ly-160);
    
    &__container {
        width: 100%;
        padding: 0 var(--lx-120);
    }
    
    &__content {
        padding: var(--ly-40) 0 0;
        border-top: 2px solid rgb(var(--rgb-01));
        display: flex;
        justify-content: space-between;
    }

    &__link {
        padding-left: 25px;
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-01));
        letter-spacing: 0.1em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: relative;
    }

    &__svg {
        width: 14px;
        height: 13px;
        stroke: rgb(var(--rgb-05));
        transition: stroke 300ms;
        stroke-width: 2px;
        fill: none;

        &--arrow-back {
            position: absolute;
            top: 50%;
            left: 0;
            transition: left 400ms var(--out-cubic);
            transform: translateY(-50%) rotate(180deg);
        }

        &--arrow-prev {
            transform: rotate(180deg);
        }
    }

    &__link:hover &__svg--arrow-back {
        left: -5px;
    }

    &__arrows-box {
        width: 60px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &::after {
            content: "";
            width: 1px;
            height: 100%;
            background: rgb(var(--rgb-01));
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__prev,
    &__next {
        display: inline-block;

        &:hover {
            svg {
                stroke: rgb(var(--rgb-04));
            }
        }
    }

    .disable {
        svg {
            stroke: rgba(var(--rgb-01), 0.3);
        }
    }
}

.pagination-variant {
    margin-top: var(--ly-150);
    margin-bottom: var(--ly-120);
    display: flex;
    
    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__arrow-container {
        opacity: 1;
        transition: opacity 300ms, transform 300ms;

        &--inactive {
            opacity: 0.3;
            cursor: initial;
            pointer-events: none;
        }

        &--prev {
            margin-right: 55px;
            transform: rotate(180deg);

            &:hover {
                transform: rotate(180deg) translateX(5px);
            }

            @media screen and (max-width: 400px) { margin-right: 30px; }
        }

        &--next {
            margin-left: 55px;

            &:hover {
                transform: translateX(5px);
            }

            @media screen and (max-width: 400px) { margin-left: 30px; }
        }
    }

    &__svg {
        width: 14px;
        height: 16px;
        stroke: rgb(var(--rgb-04));
        stroke-width: 2px;
        fill: none;
    }

    &__list {
        display: flex;
    }

    &__list-item {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }

    &__number {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-01));
        opacity: 1;
        transition: opacity 300ms;
        position: relative;

        &::before {
            content: "";
            width: 32px;
            height: 32px;
            border-radius: 4px;
            background: rgb(var(--rgb-white));
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            @media screen and (max-width: 500px) {
                width: 30px;
                height: 30px;
            }
        }

        &--active {
            cursor: initial;
            pointer-events: none;

            &::before {
                opacity: 1;
            }
        }

        p {
            position: relative;
            z-index: 2;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}