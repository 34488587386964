:root{
    --title-field__size: 2.5em;
}

.title-field {
    margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

    &__heading {
        font-size: var(--title-field__size);
        font-weight: 700;
        line-height: 1.4em;
    }
}