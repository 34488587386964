:root {
    --form-note__color: rgb(var(--rgb-03));
}

.form-note {
    margin-top: 8px;
    color: var(--form-note__color);
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7142857143em;
}
