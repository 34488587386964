@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.small-showcase {
    
}


// ----------------
// Small Showcase
// ----------------
.small-showcase {
    width: 100%;
    margin-top: var(--lx-140);
    margin-bottom: var(--ly-80);
    position: relative;
    
    &__container {
        width: 100%;
        padding: 0 var(--lx-120);
    }

    &__link {
        width: 100%;
        padding: var(--ly-110) var(--lx-100) 0 var(--lx-140);
        background: rgb(var(--rgb-02));
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: 900px) {
            padding-top: 20px;
            flex-direction: column;
            justify-content: initial;
        }
    }

    &__text-block {
        padding-top: 25px;
        align-self: flex-start;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 900px) { margin-bottom: 40px; }
    }

    &__title {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-70);
        font-weight: 600;
        line-height: 1em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    &__button {
        pointer-events: none;
    }

    &__link:hover &__button {
        color: rgb(var(--rgb-white));
        background-color: rgb(var(--rgb-04));
        border-color: rgb(var(--rgb-04));

        svg {
            fill: rgb(var(--rgb-white));
        }

        .svg-arrow {
            stroke: rgb(var(--rgb-white));
            fill: none;
        }
    }

    &__element {
        position: relative;
        z-index: 5;
    }

    &__picture-container {
        width: 420px;
        position: relative;

        @media screen and (max-width: 1100px) { width: 320px; }
        @media screen and (max-width: 900px) { width: 100%; }
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        overflow: hidden;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 400ms var(--out-cubic);
    }

    &__plus-box {
        width: 80px;
        height: 80px;
        background: rgb(var(--rgb-04));
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;

        &::before,
        &::after {
            content: "";
            width: 19px;
            height: 2px;
            background: rgb(var(--rgb-white));
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &::before {
            transform: translate(-50%,-50%) rotate(90deg);
        }

        &::after {
            transform: translate(-50%,-50%);
        }
    }

    &__link:hover &__img {
        transform: scale(1.1);
    }

    &__element-text {
        padding-top: 25px;
        padding-right: 25px;
    }

    &__element-title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-35);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-01));
        margin-bottom: 10px;
    }

    &__element-subtitle {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-01));
    }

    &__big-bg-text-container {
        width: 100%;
        height: 454px;
        background: rgb(var(--rgb-01));
        position: absolute;
        top: 0;
        left: 0;

        @media screen and (max-width: 1100px) { height: 354px; }
        @media screen and (max-width: 900px) { height: 75%; }
        @media screen and (max-width: 400px) { height: 60%; }
    }

    &__big-bg-text {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-230);
        font-weight: 700;
        line-height: 0.73em;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: rgb(var(--rgb-10));
        position: absolute;
        bottom: 0;
        left: 0;

        @media screen and (max-width: 900px) { display: none; }
    }
}
