@use "../base/typography";


//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.errors {
    
}


// ----------------
// Pages d'erreurs
// ----------------
.errors {
    width: 100%;
    padding-top: var(--ly-100);
    padding-bottom: var(--ly-100);
    display: flex;
    justify-content: center;

    $err: '.errors'; // Variable désignant errors


    #{$err}__container {
        background: rgb(var(--rgb-01));
        padding: 50px var(--ly-100);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 50px 25px;
        }
    }

    #{$err}__home {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    #{$err}__link {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        margin-right: 15px;
        transform: translateY(-2px);
    }

    #{$err}__svg {
        width: 100%;
        height: 100%;
        fill: rgb(var(--rgb-white));
        stroke: none;

        &:hover { fill: rgb(var(--rgb-04)); }
        transition: fill 300ms;
    }

    #{$err}__title {
        @extend .large-title;
        color: rgb(var(--rgb-white));
        text-align: center;
        padding-bottom: 50px;
    }

    #{$err}__text {
        @extend .paragraph;
        color: rgb(var(--rgb-white));
    }

    #{$err}__btn-home {
        @extend .paragraph;
        color: rgb(var(--rgb-white));
        transition: color 300ms;
        margin-top: 50px;

        &:hover {
            color: rgb(var(--rgb-04));
        }
    }
}