@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.fourth-zone {
    
}


// ----------------
// Fourth Zone
// ----------------

.fourth-zone {
    
    &__container {
        width: 100%;
        padding: var(--ly-190) var(--lx-120) var(--ly-170);
        background: rgb(var(--rgb-01));
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 500px) { padding-bottom: 65px; }
    }

    &__text-block {
        text-align: center;
    }

    &__text {
        @extend %introduction;
        color: rgb(var(--rgb-white));
        margin-bottom: 25px;

        strong {
            font-weight: 700;
        }

        @media screen and (max-width: 500px) { margin-bottom: 30px; }
    }

    &__button {
        margin-top: var(--ly-70);
    }
}