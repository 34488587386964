.news {
    --image-width: 330px;

    @media screen and (max-width: 768px) { --image-width: 300px; }
}

.news {
    color: rgb(var(--rgb-01));

    &__container {
        background: rgb(var(--rgb-white));
        display: flex;
        position: relative;

        @media screen and (max-width: 700px) { flex-direction: column; }
    }

    &__picture-container {
        width: var(--image-width);
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 700px) { width: 100%; }
    }

    &__picture {
        width: 100%;
        min-height: 100%;
        padding-top: 100%;
        position: relative;

        @media screen and (max-width: 700px) { min-height: 0; }
    }

    &__img {
        transition: transform 300ms;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &:hover &__img {
        transform: scale(1.1);
    }

    &__plus {
        width: 219px;
        height: var(--square-dimension);
        background: rgb(var(--rgb-04));
        display: flex;
        align-items: center;
        padding-left: var(--square-dimension);
        position: absolute;
        right: -147px;
        transition: right 400ms var(--out-cubic);
        bottom: 0;

        &::before,
        &::after {
            content: "";
            width: 17px;
            height: 2px;
            background: rgb(var(--rgb-white));
            position: absolute;
            top: 50%;
            left: 30px;

            @media screen and (max-width: 500px) { 
                width: 13px; 
                left: 50%;
            }
        }

        &::before {
            transform: translateY(-50%);

            @media screen and (max-width: 500px) { transform: translate(-50%,-50%); }
        }

        &::after {
            transform: translateY(-50%) rotate(-90deg);

            @media screen and (max-width: 500px) { transform: translate(-50%,-50%) rotate(-90deg); }
        }

        @media screen and (max-width: 500px) { 
            width: 52px;
            height: 52px; 
            padding-left: 0;
            align-items: initial;
            right: 0;
        }
    }

    &:hover &__plus {
        right: 0;
    }

    &__plus-text {
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;

        @media screen and (max-width: 500px) { display: none; }
    }

    &__text {
        width: calc(100% - var(--image-width));
        padding: 50px 120px 50px 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media screen and (max-width: 1024px) { padding: 50px 100px 50px 75px; }
        @media screen and (max-width: 900px) { padding: 40px 50px; }
        @media screen and (max-width: 700px) { width: 100%; }
        @media screen and (max-width: 600px) { padding: 40px 30px; }
    }

    &__date {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-04));
        margin-bottom: 15px;
    }

    &__title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-33);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-01));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-04));
    }

    &__category {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 500;
        color: rgb(var(--rgb-01));
        line-height: 1.3em;
        margin-top: 30px;

        @media screen and (max-width: 700px) { margin-top: 15px; }
    }
}