@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    --expertises-list-gap: 35px;

    @media screen and (max-width: 1400px) { --expertises-list-gap: 30px; }
    @media screen and (max-width: 1300px) { --expertises-list-gap: 25px; }
    @media screen and (max-width: 1200px) { --expertises-list-gap: 20px; }
    @media screen and (max-width: 950px)  { --expertises-list-gap: 10px; }
    @media screen and (max-width: 500px)  { --expertises-list-gap: 0px; }
}

//-------------------
// Variables locales
//-------------------
.expertises-list {
    
}


// ----------------
// Expertises List
// ----------------
.expertises-list {

    &__container {
        padding: 0 var(--lx-120);
        margin-top: var(--ly-130);
        margin-bottom: var(--ly-90);
    }

    &__content {
        width: calc(100% + (var(--expertises-list-gap) * 2));
        margin-left: calc(0px - var(--expertises-list-gap));
        margin-right: calc(0px - var(--expertises-list-gap));
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}