/*
|--------------------------------------------------------------------------
| Les Font-face
|--------------------------------------------------------------------------
|
| La déclaration des polices de caractères.
|
| 100: Thin (Hairline)
| 200: Extra Light (Ultra)
| 300: Light
| 400: Normal (Regular, Book)
| 500: Medium
| 600: Semi Bold (Demi)
| 700: Bold
| 800: Extra Bold (Ultra)
| 900: Black (Heavy)
|
*/

/*
|--------------------------------------------------------------------------
| Font family 1 | Roboto
|--------------------------------------------------------------------------
*/

// Bold
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Bold | Italic
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

// Medium
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// Medium | Italic
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// Regular
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// Regular | Italic
@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Italic.ttf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}


/*
|--------------------------------------------------------------------------
| Font family 2 | Tungsten
|--------------------------------------------------------------------------
*/

// Bold
@font-face {
    font-family: "Tungsten";
    src: url("../fonts/Tungsten-Bold.woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Semi Bold
@font-face {
    font-family: "Tungsten";
    src: url("../fonts/Tungsten-SemiBold.woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// Medium
@font-face {
    font-family: "Tungsten";
    src: url("../fonts/Tungsten-Medium.woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// Regular
@font-face {
    font-family: "Tungsten";
    src: url("../fonts/Tungsten-Book.woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
