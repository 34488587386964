:root {
    --form__label-color: rgb(var(--rgb-01));
    --form__label-size: 1.7rem;
}

.form-label {
    display: block;
    padding-bottom: 12px;
    font-size: var(--form__label-size);
    font-weight: 500;
    line-height: 1em;
    color: var(--form__label-color);
}
