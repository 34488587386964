.page {
    background: rgb(var(--rgb-02));
    display: flow-root;

    &--home {
        .showcase {
            margin-top: 0;
        }
    }

    &--jobs-list {
        .showcase--white {
            padding-top: var(--ly-150);
        }
    }

    &--jobs-detail {
        .page-title {
            &--no-image {
                .page-title__container {
                    padding-left: var(--lx-120);
                }
                .page-title__text-block {
                    padding-left: var(--lx-80);

                    @media screen and (max-width: 700px) { padding-left: 0; }
                }
            }
        }
    }

    &--about {
        .page-title {
            .page-title__container {

                &::after {
                    height: 145px;
                }
        
                @media screen and (max-width: 600px) {
                    &::after {
                        height: 145px;
                    }
                }
            }
        }
    }

    &--about,
    &--jobs-detail,
    &--jobs-list,
    &--stylesheet {

        .page-title .page-title__container {
            margin-bottom: 0;
        }

        .main {
            padding-top: var(--ly-110);
        }
    }

    &--contact {

        .breadcrumb .breadcrumb__container {
            padding-left: 0;
        }

        .page-title-contact .page-title-contact__container {
            margin-bottom: 0;
        }
    }
}