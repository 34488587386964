@use "../../base/typography.scss";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.overlay-share {
    
}


// ----------------
// Overlay | Share
// ----------------

.overlay-share {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 80;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

    &__container {
        position: absolute;
        padding: 85px;
        width: 520px;
        top: 50%;
        left: 50%;
        background: #fff;
        text-align: center;
        opacity: 0;
        z-index: 151;
        pointer-events: none;
        transform: translateX(-50%);
        transition: opacity 800ms var(--in-out-quart), transform 800ms var(--in-out-quart);
        will-change: transform;

        @media screen and (max-width: 1200px) {
            width: 490px;
        }

        @media screen and (max-width: 1024px) {
            width: 400px;
            padding: 50px;
        }

        @media screen and (max-width: 600px) {
            width: auto;
            right: 20px;
            left: 20px;
            padding: 50px 20px;
            transform: none;
        }
    }

    &__title {
        @extend .medium-title;
        margin-bottom: 20px;
        color: rgb(var(--rgb-01));
    }

    &__close {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 20px;
        right: 20px;
        cursor: pointer;

        &:hover::before,
        &:hover::after {
            background: var(--color-green-base);
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: rgb(var(--rgb-01));
            transition: background 300ms;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__list {
        position: relative;
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }

    &__list-li {
        &:not(:last-child) {
            margin-right: 20px;

            @media screen and (max-width: 600px) {
                margin-right: 10px;
            }
        }
    }

    &__link {
        position: relative;
        width: 45px;
        height: 45px;
        background: rgb(var(--rgb-01));
        border-radius: 50%;
        transition: background 300ms;
        display: block;
        cursor: pointer;

        &:hover {
            background: rgb(var(--rgb-04));
        }
    }

    &__svg {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        fill: rgb(var(--rgb-white));
        transform: translate(-50%, -50%);

        &--mail {
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
            fill: none;
        }
    }
}

.show-overlay-share {
    .overlay-share {
        pointer-events: all;
        visibility: visible;

        &__background {
            background: rgba(#000, 0.4);
        }

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) {
                transform: translateY(-50%);
            }
        }
    }
}

.show-overlay-share.closing-overlay-share {
    .overlay-share {
        &__background {
            background: rgba(#000, 0);
        }

        &__container {
            opacity: 0;
            transform: translateX(-50%);

            @media screen and (max-width: 600px) {
                transform: none;
            }
        }
    }
}
