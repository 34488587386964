//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.bg-grid {
    --small-column-width: 120px;
    --large-column-width: 400px;
}


// ----------------
// Background Grid
// ----------------
.bg-grid {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    pointer-events: none;
    // mix-blend-mode: exclusion;

    &__large-column {
        border-left: 1px solid;
        border-color: rgba(var(--rgb-14), 0.15);
        flex: 1;

        &:nth-child(4) {
            @media screen and (max-width: 600px) { display: none; }
        }
    }

    &__small-column {
        width: var(--lx-120);

        &:last-child {
            border-left: 1px solid;
            border-color: rgba(var(--rgb-14), 0.15); 
        }
    }

    &__small-column,
    &__large-column {
        height: 0;

        animation-name: line-tracing;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;

        @keyframes line-tracing {
            0%   {height: 0;}
            100% {height: 100%;}
        }

        &:nth-child(2) { animation-delay: 1000ms; }
        &:nth-child(3) { animation-delay: 1200ms; }
        &:nth-child(4) { animation-delay: 1400ms; }
        &:last-child { 
            animation-delay: 1600ms; 

            @media screen and (max-width: 600px) { animation-delay: 1400ms;  }
        }
    }
}