@use "../base/typography";

.news-list {
    margin-bottom: var(--ly-150);
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: var(--ly-120);
        background: rgb(var(--rgb-01));
        position: absolute;
        top: 0;
        left: 0;
    }

    &--no-news {
        &::before {
            display: none;
        }
    }

    &__container {
        width: 100%;
        padding: 0 var(--lx-120);
    }

    &--no-news &__container {
        padding-top: var(--ly-150);
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 20px;

            @media screen and (max-width: 700px) { margin-bottom: 50px; }
        }
    }

    &__title {
        @extend %large-title;
        color: rgb(var(--rgb-01));
        margin-bottom: 30px;
    }

    &__home-link {
        @extend %paragraph;
        color: rgb(var(--rgb-01));
        text-decoration: none;
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-07));
        transition: box-shadow 0.3s;

        &:hover {
            box-shadow: inset 0 -1.1em 0 0 rgb(var(--rgb-07));
        }
    }
}