@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}

//-------------------
// Variables locales
//-------------------
.activities-section {
    
}


// ----------------
// Activities Section
// ----------------
.activities-section {

    &__container {
        width: 100%;
        padding: var(--ly-290) 0 var(--ly-220) var(--lx-120);
        background: rgb(var(--rgb-06));
        display: flex;
        position: relative;

        @media screen and (max-width: 800px) { 
            flex-direction: column; 
            align-items: flex-start;
            overflow: hidden;
        }

        @media screen and (max-width: 500px) { 
            padding-top: 60px; 
            padding-bottom: 70px;
        }
    }

    &__big-text {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-248);
        font-weight: 700;
        line-height: 0.73em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-10));
        text-transform: uppercase;
        position: absolute;
        top: var(--ly-150);
        right: 0;

        @media screen and (max-width: 800px) {
            margin-left: -10px;
            top: initial;
            right: initial;
            bottom: 0;
            left: 0;
        }
    }

    &__left-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 800px) {
            width: 100%;
            flex: initial;
        }
    }

    &__title-block {
        margin-bottom: 120px;

        @media screen and (max-width: 1024px) { margin-bottom: 100px; }
        @media screen and (max-width: 900px) { margin-bottom: 50px; }
        @media screen and (max-width: 800px) { margin-bottom: 20px; }
    }

    &__title {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-70);
        font-weight: 600;
        line-height: 1em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: 35px;
        margin-left: -1px;

        @media screen and (max-width: 800px) { margin-bottom: 0; }
    }

    &__btn {

        &--desktop {
            @media screen and (max-width: 800px) { display: none; }
        }

        &--mobile {
            display: none;

            @media screen and (max-width: 800px) { display: inline-flex; }
        }
    }

    &__slider-nav {
        display: flex;

        @media screen and (max-width: 800px) { margin-bottom: 25px; }
    }

    &__slider-nav-link {
        width: 73px;
        height: 73px;
        background: transparent;
        transition: background 300ms;
        border-radius: 50%;
        border: 2px solid rgb(var(--rgb-white));
        position: relative;

        &:first-child {
            margin-right: 25px;

            @media screen and (max-width: 500px) { margin-right: 15px; }
        }

        &:hover {
            background: rgb(var(--rgb-white));
        }

        @media screen and (max-width: 500px) {
            width: 45px;
            height: 45px;
        }
    }

    &__slider-nav-svg {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-white));
        transition: stroke 300ms, transform 400ms var(--out-cubic);
        stroke-width: 2px;
        fill: none;
        transform: translateX(0);
        position: absolute;
        top: 50%;
        left: 50%;
        will-change: transform;

        &--prev {
            transform: translate(-50%,-50%) rotate(180deg);
        }

        &--next {
            transform: translate(-50%,-50%);
        }
    }

    &__slider-nav-link:hover &__slider-nav-svg {
        stroke: rgb(var(--rgb-05));

        &--prev {
            transform: translate(calc(-50% - 10px),-50%) rotate(180deg);
        }

        &--next {
            transform: translate(calc(-50% + 10px), -50%);
        }
    }

    &__right-section {
        flex: 2.3;
        overflow: hidden;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 40px;
            flex: initial;
        }
    }

    &__slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    &__slider-slide {
        height: 100%;
    }

    .slick-list {
        @media screen and (max-width: 400px) { padding-right: var(--lx-120); }
    }

    &__slider {
        width: 100%;
    }

    &__slider-slide {
        margin-right: 40px;

        @media screen and (max-width: 1024px) { margin-right: 30px; }
        @media screen and (max-width: 400px) { margin-right: 0; }
    }
}