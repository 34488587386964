@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.showcase {
    
}


// ----------------
// Showcase
// ----------------
.showcase {
    margin-top: var(--ly-100);
    
    &__container {
        width: 100%;
        padding-top: var(--ly-120);
        padding-right: var(--lx-120);
        position: relative;

        &::after {
            content: "";
            width: calc(100% - var(--lx-120));
            height: 100%;
            background: rgb(var(--rgb-04));
            position: absolute;
            top: 0;
            right: 0;
        }

        @media screen and (max-width: 900px) {
            padding-top: var(--ly-80);
            padding-right: var(--lx-80);
        }

        @media screen and (max-width: 600px) {
            padding: 0;

            &::after {
                display: none;
            }
        }
    }

    &__picture-container {
        width: 100%;
        min-height: 578px;
        padding: 65px 25px 65px var(--lx-120);
        display: flex;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 1400px) { min-height: 568px; }
        @media screen and (max-width: 1350px) { min-height: 548px; }
        @media screen and (max-width: 1300px) { min-height: 528px; }
        @media screen and (max-width: 1250px) { min-height: 508px; }
        @media screen and (max-width: 1200px) { min-height: 488px; }
        @media screen and (max-width: 1150px) { min-height: 468px; }
        @media screen and (max-width: 1100px) { min-height: 448px; }
        @media screen and (max-width: 1050px) { min-height: 428px; }
        @media screen and (max-width: 1000px) { min-height: 408px; }
        @media screen and (max-width: 950px) { min-height: 388px; }
        @media screen and (max-width: 900px) { min-height: 368px; }
        @media screen and (max-width: 850px) { min-height: 348px; }
        @media screen and (max-width: 800px) { min-height: 328px; }
    }

    &__filter,
    &__gradient {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }

    &__filter {
        background: rgba(var(--rgb-09), 0.5);
        z-index: 5;
    }

    &__gradient {
        background: rgb(var(--rgb-01));
        background: linear-gradient(270deg, rgba(var(--rgb-01),0) 0%, rgba(var(--rgb-01),0.5) 100%);
        z-index: 6;
    }

    &__picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        align-self: center;
        position: relative;
        z-index: 10;
    }

    &__surtitle {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        line-height: 1.5em;
        letter-spacing: 0.050em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: 25px;

        @media screen and (max-width: 900px) { margin-bottom: 15px; }
    }

    &__title {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-70);
        font-weight: 600;
        line-height: 1em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: 50px;
        margin-left: -1px;

        @media screen and (max-width: 900px) { margin-bottom: 20px; }
    }
}

.showcase--white {
    background: rgb(var(--rgb-white));
    padding-top: var(--ly-100);
    margin-top: 0;
}