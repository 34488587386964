:root {
    --form-upload__background: rgb(var(--rgb-15));
    --form-upload__background-hover: rgb(var(--rgb-04));
    --form-upload__x-background: #808080;
    --form-upload__x-background-hover: black;
    --form-upload__border-color: #b2b2b2;
    --form-upload__color: rbg(var(--rgb-01));
}

.control-multi-file-uploader {
    background-color: var(--form-upload__background);
    border: 1px dashed var(--form-upload__border-color);
    border-radius: 4px;

    .container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .dz-clickable {
        height: 100px;
        cursor: pointer;
        transition: color 300ms, background 300ms;

        &:hover { 
            background-color: var(--form-upload__background-hover);
        }
    }

    .content {
        font-size: 1.5em;

        .placeholder {
            position: absolute;
            top: 50px;
            right: 20px;
            left: 20px;
            line-height: 1.3em;
            font-weight: 600;
            color: var(--form-upload__color);
            text-align: center;
            pointer-events: none;
            transform: translate(0, -50%);
            transition: color 300ms, background 300ms;

            .upload-full-text {
                display: block;

                @media screen and (max-width: 560px) { display: none }
            }
            
            .upload-truncated-text {
                display: none;

                @media screen and (max-width: 560px) { display: block; }
            }

            svg {
                width: 14px;
                height: 14px;
                stroke: rgb(var(--rgb-01));
                fill: rgb(var(--rgb-01));
                margin-left: 20px;
                transform: translateY(3px);
            }
        }

        .dz-preview {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 100px;
            padding-left: 120px;
            padding-right: 35px;
            margin: 20px;
            background-color: rgba(#f7f8f8, 0.3);

            .thumbnail {
                position: absolute;
                width: 100px;
                height: 100px;
                top: 0;
                left: 0;
            }

            .dz-details {
                flex-wrap: wrap;
                font-size: 1em;
                line-height: 1.5em;
                color: #012040;

                .dz-filename,
                .dz-size {
                    width: 100%;
                    margin: 6px 0;
                }
            }

            .action-panel {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 10px;
                right: 10px;
                color: white;
                background: var(--form-upload__x-background);
                border-radius: 50%;
                cursor: pointer;
                z-index: 4;
                transition: color 300ms, background 300ms;

                &:hover {
                    background: var(--form-upload__x-background-hover);
                }

                a {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: white;
                    transform: translate(-50%,-50%);
                }
            }

            .dz-error-mark {
                display: none;
            }
            
            .dz-error-message {
                position: absolute;
                bottom: 5px;
                color: #458a91;
            }
        }
    }

    .container:hover {
        .content .placeholder {
            color: white!important;
        }
    }
}