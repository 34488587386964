@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.footer {
    --bottom-section-height: 120px;

    @media screen and (max-width: 768px) { --bottom-section-height: 100px; }
}


// ----------------
// Footer
// ----------------
.footer {

    &__container {
        width: 100%;
        padding-top: var(--ly-100);
        background: rgb(var(--rgb-06));

        @media screen and (max-width: 500px) { padding-top: 50px; }
    }

    &__top-section {
        padding: 0 var(--lx-120) var(--ly-120);
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 500px) { padding-bottom: 45px; }
    }

    &__left-section {
        flex: 1;
        margin-top: -5px;

        @media screen and (max-width: 1000px) { margin-top: -3px; }
        @media screen and (max-width: 900px) { margin-top: -1px; }
        @media screen and (max-width: 800px) { margin-top: 0; }
    }

    &__list-item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link-item {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-70);
        font-weight: 600;
        line-height: 1em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        transition: color 300ms;
        text-transform: uppercase;
        margin-left: -1px;

        &:hover {
            color: rgb(var(--rgb-04));
        }
    }

    &__middle-section {
        flex: 1;

        &--top {  
            @media screen and (max-width: 768px) { display: none; }
        }

        &--bottom {
            @media screen and (max-width: 600px) { display: none; }
        }
    }

    &__right-section {
        flex: 1;

        @media screen and (max-width: 768px) { display: none; }
    }

    &__right-section-links {
        display: flex;
        margin-bottom: 60px;
    }

    &__small-link-list {
        &:first-child {
            margin-right: var(--lx-100);
        }
    }

    &__small-list-item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__small-link-item {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__emergency,
    &__email {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }

    &__emergency {
        margin-bottom: 20px;
    }

    &__phone {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-35);
        font-weight: 700;
        line-height: 1.2em;
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;
        margin-bottom: 30px;
        margin-left: -1px;
        display: block;

        &:hover {
            opacity: 0.5;
        }
    }

    &__email {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-07));
        transition: box-shadow 0.3s;
        padding-bottom: 4px;

        &:hover {
            box-shadow: inset 0 -1.3em 0 0 rgb(var(--rgb-07));
        }
    }

    &__bottom-section {
        width: 100%;
        position: relative;

        &::before {
            content: "";
            width: 100%;
            height: 1px;
            background: rgba(var(--rgb-14), 0.4);
            // mix-blend-mode: exclusion;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
        }

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }

    &__bottom-section-content {
        height: var(--bottom-section-height);
        padding: 0 var(--lx-120);
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__copyright {
        display: flex;
        align-items: center;
        flex: 1;

        @media screen and (max-width: 1000px) { 
            flex-direction: column; 
            align-items: flex-start;
        }
        @media screen and (max-width: 600px) {
            width: 100%;
            flex: initial;
            flex-direction: initial;
            align-items: center;
            justify-content: space-between;
            order: 2;
        }
    }

    &__copyright-item {
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        margin-right: 40px;

        @media screen and (max-width: 1000px) { margin-bottom: 20px; }
        @media screen and (max-width: 600px) { margin-bottom: 0; }
        @media screen and (max-width: 500px) { font-size: 1rem; }
    }

    &__copyright-link {
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.3em;
        color: rgb(var(--rgb-11));
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 500px) { font-size: 1rem; }
    }

    &__social {
        flex: 1;

        @media screen and (max-width: 600px) { 
            width: 100%;
            flex: initial;
            order: 1;
            margin-bottom: 20px;
        }
    }

    &__social-link {
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__svg {
        stroke: rgb(var(--rgb-white));

        &--facebook {
            width: 11px;
            height: 20px;
            fill: rgb(var(--rgb-white));
            margin-right: 30px;

            @media screen and (max-width: 500px) {
                width: 8px;
                height: 19px;
            }
        }

        &--linkedin {
            width: 20px;
            height: 19px;
            fill: rgb(var(--rgb-white));

            @media screen and (max-width: 500px) {
                width: 19px;
                height: 16px;
            }
        }

        &--arrow {
            width: 13px;
            height: 14px;
            stroke-width: 2px;
            fill: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(-90deg);
        }
    }

    &__return-button {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 54px;
            padding: 0 var(--lx-120);
            background: rgb(var(--rgb-04));
            flex-direction: initial;
            align-items: center;
            position: relative;
            right: initial;
            bottom: initial;
        }
    }

    &__return-button-link {
        @media screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    &__return-button-square {
        width: var(--lx-120);
        height: var(--bottom-section-height); 
        background: rgb(var(--rgb-04));
        transition: background 300ms;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 13px;
            height: 14px;
            background: transparent;
            order: 1;
        }
    }

    &__return-button-text {
        width: var(--lx-120);
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: var(--lx-120);
        color: rgb(var(--rgb-white));
        writing-mode: vertical-rl;
        opacity: 1;
        transition: opacity 300ms;
        margin-bottom: 35px;
        transform: rotate(-180deg);

        @media screen and (max-width: 768px) {
            width: 100%;
            line-height: 1.5em;
            writing-mode: initial;
            margin-bottom: 0;
            margin-left: 18px;
            order: 2;
            transform: initial;
        }
    }

    &__return-button:hover &__return-button-text {
        opacity: 0.5;
    }

    &__return-button:hover &__return-button-square{
        background: rgb(var(--rgb-05));
    }
}