@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.activity {
    --square-dimension: 72px;

    @media screen and (max-width: 500px) { --square-dimension: 52px; }

    --picture-dimension: 377px;

    @media screen and (max-width: 1400px) { --picture-dimension: 350px; }
    @media screen and (max-width: 1300px) { --picture-dimension: 325px; }
    @media screen and (max-width: 1200px) { --picture-dimension: 300px; }
    @media screen and (max-width: 1100px) { --picture-dimension: 277px; }
    @media screen and (max-width: 340px) { --picture-dimension: 250px; }
}


// ----------------
// Activity
// ----------------
.activity {
    
    &__container {
        max-width: var(--picture-dimension);
        height: 100%;
        display: block;
    }

    &__picture-container {
        width: var(--picture-dimension);
        overflow: hidden;
        position: relative;
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 400ms var(--out-cubic);
    }

    &__container:hover &__img {
        transform: scale(1.1);
    }

    &__plus {
        width: 219px;
        height: var(--square-dimension);
        background: rgb(var(--rgb-04));
        display: flex;
        align-items: center;
        padding-left: var(--square-dimension);
        position: absolute;
        right: -147px;
        transition: right 400ms var(--out-cubic);
        bottom: 0;

        &::before,
        &::after {
            content: "";
            width: 17px;
            height: 2px;
            background: rgb(var(--rgb-white));
            position: absolute;
            top: 50%;
            left: 30px;

            @media screen and (max-width: 500px) { 
                width: 13px; 
                left: 50%;
            }
        }

        &::before {
            transform: translateY(-50%);

            @media screen and (max-width: 500px) { transform: translate(-50%,-50%); }
        }

        &::after {
            transform: translateY(-50%) rotate(-90deg);

            @media screen and (max-width: 500px) { transform: translate(-50%,-50%) rotate(-90deg); }
        }

        @media screen and (max-width: 500px) { 
            width: 52px;
            height: 52px; 
            padding-left: 0;
            align-items: initial;
            right: 0;
        }
    }

    &__container:hover &__plus {
        right: 0;
    }

    &__plus-text {
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;

        @media screen and (max-width: 500px) { display: none; }
    }

    &__text-block {
        padding-top: 30px;

        @media screen and (max-width: 800px) { padding-top: 20px; }
    }

    &__title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-35);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__text {
        font-family: var(--ff-1); // Roboto
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.4em;
        color: rgb(var(--rgb-white));
        margin-top: 15px;

        @media screen and (max-width: 800px) { margin-top: 10px; }
    }
}