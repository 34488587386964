:root {
    --checkbox-field__master-label-color: rgb(var(--rgb-01));

    --checkbox-field__label-color: rgb(var(--rgb-01));

    --checkbox-field__box-border: 1px solid rgba(var(--rgb-01), 0.2);
    --checkbox-field__box-border-active: 1px solid rgb(var(--rgb-04));
    --checkbox-field__box-color: transparent;
    --checkbox-field__box-color-active: transparent;
    --checkbox-field__box-background-color: rgb(var(--rgb-white));
    --checkbox-field__box-background-color-active: rgb(var(--rgb-04));
}


.checkbox-field {
    &__main-label {
        display: block;
        margin-bottom: 30px;
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1em;
        color: var(--checkbox-field__master-label-color);
    }

    &__label {
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        flex: 1;
        font-size: 1.5rem;
        line-height: 1.6em;
        color: var(--checkbox-field__label-color);
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__link {
        span {
            font-size: 1.5rem;
            line-height: 1.6em;
            color: var(--checkbox-field__label-color) !important;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__box {
        position: relative;
        width: 18px;
        height: 18px;
        margin-right: 15px;
        background-color: var(--checkbox-field__box-background-color);
        border: var(--checkbox-field__box-border);
        border-radius: 3px;
        order: 1;
        transition: background-color 225ms, border 225ms;
        transform: translateY(2px);

        &:before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            background: url(../medias/images/icons/checkmark.svg) no-repeat center/contain;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
        }
    }

    &__error {
        position: absolute;
    }

    &__input:checked~&__box {
        background-color: var(--checkbox-field__box-background-color-active);
        border: var(--checkbox-field__box-border-active);
    }

    &__input:checked~&__box::before {
        transform: translate(-50%, -50%) scale(1);
    }

    &--inline &__options {
        margin-bottom: -20px;
    }

    &--inline &__label {
        display: inline-flex;
        margin: 0 30px 20px 0;
    }
}
