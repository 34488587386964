@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.second-zone {
    
}


// ----------------
// Second Zone
// ----------------
.second-zone {
    margin-top: var(--ly-160);

    @media screen and (max-width: 500px) { margin-top: 45px; }
    
    &__container {
        width: 100%;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 290px;
            background: rgb(var(--rgb-01));
            position: absolute;
            bottom: 0;
            left: 0;

            @media screen and (max-width: 900px) { height: 250px; }
            @media screen and (max-width: 800px) { height: 200px; }
            @media screen and (max-width: 700px) { height: 190px; }
        }

        @media screen and (max-width: 700px) { padding-left: var(--lx-120); }
    }

    &__slider-nav {
        display: flex;
        position: absolute;
        top: 0;
        right: var(--lx-120);
        z-index: 10;

        @media screen and (max-width: 700px) {
            margin-bottom: 30px;
            position: relative;
            top: initial;
            right: initial;
            z-index: initial;
        }
    }

    &__slider-nav-link {
        width: 73px;
        height: 73px;
        background: transparent;
        transition: background 300ms;
        border-radius: 50%;
        border: 2px solid rgb(var(--rgb-05));
        position: relative;

        &:first-child {
            margin-right: 25px;

            @media screen and (max-width: 700px) { margin-right: 15px; }
        }

        &:hover {
            background: rgb(var(--rgb-05));
        }

        @media screen and (max-width: 1024px) {
            width: 50px;
            height: 50px;
        }
        @media screen and (max-width: 800px) {
            width: 40px;
            height: 40px;
        }
        @media screen and (max-width: 700px) {
            width: 45px;
            height: 45px;
        }
    }

    &__slider-nav-svg {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-05));
        transition: stroke 300ms, transform 400ms var(--out-cubic);
        stroke-width: 2px;
        fill: none;
        position: absolute;
        top: 50%;
        left: 50%;
        will-change: transform;

        &--prev {
            transform: translate(-50%,-50%) rotate(180deg);
        }

        &--next {
            transform: translate(-50%,-50%);
        }
    }

    &__slider-nav-link:hover &__slider-nav-svg {
        stroke: rgb(var(--rgb-white));

        &--prev {
            transform: translate(calc(-50% - 10px),-50%) rotate(180deg);
        }

        &--next {
            transform: translate(calc(-50% + 10px), -50%);
        }
    }

    &__slider {
        position: relative;
        z-index: 5;

        .slick-slide {
            margin-right: var(--lx-140);

            @media screen and (max-width: 600px) { margin-right: 30px; }
        }

        .slick-list {
            @media screen and (max-width: 400px) { padding-right: var(--lx-120); }
        }
    }

    &__slider-slide  {
        width: 600px!important;

        @media screen and (max-width: 1200px) { width: 500px!important; }
        @media screen and (max-width: 1024px) { width: 400px!important; }
        @media screen and (max-width: 800px) { width: 300px!important; }
        @media screen and (max-width: 700px) { width: 500px!important; }
        @media screen and (max-width: 600px) { width: 400px!important; }
        @media screen and (max-width: 500px) { width: 300px!important; }
        @media screen and (max-width: 400px) { width: 275px!important; }
        @media screen and (max-width: 360px) { width: 250px!important; }
    }

    &__picture-container {
        width: 100%;
        padding-top: 127%;
        position: relative;
    }

    &__picture {
        overflow: hidden;
        position: absolute;
        top: 13.5%;
        right: 0;
        bottom: 13.5%;
        left: 0;
        transition: top 500ms var(--out-cubic), bottom 500ms var(--out-cubic);
        transition-delay: 0s;
    }

    &__slider .slick-slide.slick-current &__picture,
    &__slider .slick-slide.slickAnimate &__picture {
        top: 0%;
        bottom: 0%;
        transition-delay: 0.5s;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}