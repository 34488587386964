@use "../base/typography";

.section-header {
    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    &__surtitle {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        line-height: 1.4em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgb(var(--rgb-04));
        margin-bottom: var(--sy-30);
    }
    
    &__title {
        margin: var(--sy-30) 0;

        &--small {
            @extend %small-title;
        }

        &--large {
            @extend %large-title;
        }

        text-transform: uppercase;
        letter-spacing: 0.025em;
    }

    &__description {
        @extend %paragraph;
        margin: var(--sy-30) 0;
    }
}