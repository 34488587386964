/*
|--------------------------------------------------------------------------
| Normalize
|--------------------------------------------------------------------------
|
| Les styles appliqués globalement au projet.
|
*/

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;

    &::before,
    &::after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

abbr[title], dfn[title] {
    text-decoration: none;
    cursor: help;
}

del {
    text-decoration: line-through;
}

html {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: var(--ff);
    font-size: 62.5%;
    vertical-align: baseline;
}

body {
    line-height: 1;
    font-family: var(--ff-1); // Élimine les Times New Roman oubliés
    color: rgb(var(--rgb-01)); // Élimine les #000000 oubliés
}

a {
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

input {
    vertical-align: middle;
    border-radius: 0;
    -webkit-appearance: none;

    &:focus {
        outline: none;
    }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration,
    &[type="search"]::-ms-clear,
    &[type="search"]::-ms-reveal { // clears the 'X' from Chrome and Internet Explorer
        display: none;
        width: 0px;
        height: 0px;
    }
}

select {
    vertical-align: middle;
    -webkit-appearance: none;
}

picture {
    display: block;
    font-size: 0;

    img {
        width: 100%;
        height: 100%;
    }

    [data-object-fit="cover"] {
        object-fit: cover;
        font-family: "object-fit: cover;";
    }

    [data-object-fit="contain"] {
        object-fit: contain;
        font-family: "object-fit: contain;";
    }
}

.slick-slide,
.slick-slide a,
.slick-slide:focus { // Retirer le outline d'un slick slider
    outline: none !important;
}

.slick-loading .slick-list { // Retirer les images introuvables du slick slide pour éviter une 404
    background: initial;
}

.fancybox-caption__body {
    font-family: var(--ff);
}

.default-container {
    position: relative;
}

.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
    display: none !important;
}
