/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
|
| Déclaration de tous les titres, textes, boutons et liens réutilisables
| du projet.
|
*/


// Titre #1 -----------------------------------------------------
%huge-title {
    font-family: var(--ff-2); // Tungsten
    font-size: var(--fz-85);
    font-weight: 600;
    letter-spacing: 0.025em;
}

.huge-title {
    @extend %huge-title;
}


// Titre #2 -----------------------------------------------------
%large-title {
    font-family: var(--ff-2); // Tungsten
    font-size: var(--fz-60);
    font-weight: 600;
    line-height: 1em;
    letter-spacing: 0.025em;
    text-transform: uppercase
}

.large-title {
    @extend %large-title;
}


// Titre #3 -----------------------------------------------------
%medium-title {
    font-family: var(--ff-1); // Roboto
    font-size: var(--fz-30);
    font-weight: 700;
    line-height: 1.3em;
}

.medium-title {
    @extend %medium-title;
}

// Titre #4 -----------------------------------------------------
%small-title {
    font-family: var(--ff-1); // Roboto
    font-size: var(--fz-25);
    font-weight: 700;
    line-height: 1.4em;
}

.small-title {
    @extend %small-title;
}


// Titre #5 -----------------------------------------------------
%tiny-title {}

.tiny-title {
    @extend %tiny-title;
}


// Titre #6 -----------------------------------------------------
%minuscule-title {}

.minuscule-title {
    @extend %minuscule-title;
}


// Introduction -------------------------------------------------
%introduction {
    font-family: var(--ff-1); // Roboto
    font-size: var(--fz-25);
    font-weight: 400;
    line-height: 1.6em;
}

.introduction {
    @extend %introduction;
}


// Texte normal (paragraphe) ------------------------------------
%paragraph {
    font-family: var(--ff-1); // Roboto
    font-size: var(--fz-17);
    font-weight: 400;
    line-height: 1.6em;
}

.paragraph {
    @extend %paragraph;
}


// Lien #1 ------------------------------------------------------
%link {}

.link {
    @extend %link;
}


// Bouton (primaire et secondaire) ------------------------------
%button {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 21px 25px;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.3em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    transition: transform 800ms var(--out-cubic), color 300ms, background-color 300ms, border 300ms;

    span {
        display: flex;
        align-items: center;
    }

    svg {
        content: "";
        flex-shrink: 0;
        display: inline-block;
        margin-left: 10px;
        transition: fill 300ms, stroke 300ms;
    }

    &[target="_blank"] svg,
    &[href$=".pdf"] svg,
    &[href$=".doc"] svg,
    &[href$=".zip"] svg {
        width: 11px;
        height: 11px;
    }

    &[target="_blank"] .svg-arrow,
    &[href$=".pdf"] .svg-arrow,
    &[href$=".doc"] .svg-arrow,
    &[href$=".zip"] .svg-arrow {
        display: none;
    }

    @media screen and (max-width: 600px) { padding: 20px; }
    @media screen and (max-width: 500px) {
        font-size: 1.1rem;
        padding: 18px;
    }
    @media screen and (max-width: 400px) { padding: 16px; }
}


// Bouton primaire | Impact ----------------------------------------------
%primary-button {
    @extend %button;
    color: rgb(var(--rgb-01));
    background-color: rgb(var(--rgb-white));
    border-color: rgb(var(--rgb-white));
    -webkit-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.05);
    box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.05);

    svg {
        fill: rgb(var(--rgb-05));
    }

    .svg-arrow {
        width: 14px;
        height: 13px;
        stroke: rgb(var(--rgb-05));
        stroke-width: 2px;
        fill: none;
        margin-left: 17px;
        transform: translate(0,-2px);
        transition: transform 400ms var(--out-cubic);
    }

    &:hover {
        color: rgb(var(--rgb-white));
        background-color: rgb(var(--rgb-04));
        border-color: rgb(var(--rgb-04));

        svg {
            fill: rgb(var(--rgb-white));
        }

        .svg-arrow {
            stroke: rgb(var(--rgb-white));
            fill: none;
            transform: translate(10px,-2px);
        }
    }

    &.disabled {
        pointer-events: none;
        color: rgb(var(--rgb-01));
        background-color: rgb(210, 210, 210);

        svg {
            fill: rgb(var(--rgb-01));
        }

        .svg-arrow {
            stroke: rgb(var(--rgb-01));
            fill: none;
            transform: translate(0,-2px);
        }

        &:hover {
            color: rgb(var(--rgb-01));
            background-color: rgb(210, 210, 210);
            cursor: not-allowed !important;

            svg {
                fill: rgb(var(--rgb-01));
            }

            .svg-arrow {
                stroke: rgb(var(--rgb-01));
                fill: none;
                transform: translate(0,-2px);
            }
        }
    }
}

.primary-button {
    @extend %primary-button;
}


// Bouton secondaire --------------------------------------------
%secondary-button {
    @extend %button;
    color: rgb(var(--rgb-01));
    background-color: transparent;
    border-color: rgb(var(--rgb-04));

    svg {
        fill: rgb(var(--rgb-05));
    }

    &:hover {
        color: rgb(var(--rgb-white));
        background-color: rgb(var(--rgb-04));

        svg {
            fill: rgb(var(--rgb-white));
        }
    }
}

.secondary-button {
    @extend %secondary-button;
}
