@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.expertise {
    --square-dimension: 72px;
}


// ----------------
// Expertise
// ----------------
.expertise {
    width: 33.33%;
    padding: 0 var(--expertises-list-gap);
    margin-bottom: var(--ly-80);

    @media screen and (max-width: 900px)  { width: 50%; }
    @media screen and (max-width: 600px)  { width: 100%; }
    
    &__container {
        height: 100%;
        display: block;
    }

    &__picture-container {
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(1);
        transition: transform 400ms var(--out-cubic);
    }

    &__container:hover &__img {
        transform: scale(1.1);
    }

    &__plus {
        width: 219px;
        height: var(--square-dimension);
        background: rgb(var(--rgb-04));
        display: flex;
        align-items: center;
        padding-left: var(--square-dimension);
        position: absolute;
        right: -147px;
        transition: right 400ms var(--out-cubic);
        bottom: 0;

        &::before,
        &::after {
            content: "";
            width: 17px;
            height: 2px;
            background: rgb(var(--rgb-white));
            position: absolute;
            top: 50%;
            left: 30px;
        }

        &::before {
            transform: translateY(-50%);
        }

        &::after {
            transform: translateY(-50%) rotate(-90deg);
        }
    }

    &__container:hover &__plus {
        right: 0;
    }

    &__plus-text {
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
    }

    &__text-block {
        padding-top: 30px;

        @media screen and (max-width: 800px) { padding-top: 20px; }
    }

    &__title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-01));
    }

    &__text {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.4em;
        color: rgb(var(--rgb-01));
        margin-top: 20px;

        @media screen and (max-width: 800px) { margin-top: 10px; }
    }
}