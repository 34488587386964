@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.first-zone {
    --picture-width: 440px;

    @media screen and (max-width: 1000px) { --picture-width: 400px; }
    @media screen and (max-width: 900px) { --picture-width: 360px; }
    @media screen and (max-width: 800px) { --picture-width: 320px; }
}


// ----------------
// First Zone
// ----------------
.first-zone {
    margin-top: var(--ly-140);
    margin-bottom: var(--ly-160);

    @media screen and (max-width: 500px) {
        margin-top: 65px;
        margin-bottom: 45px;
    }

    &__container {
        width: 100%;
        padding: 0 var(--lx-120);
    }
    
    &__top-section {
        width: 100%;
        margin-bottom: var(--ly-100);
        display: flex;
        justify-content: center;

        @media screen and (max-width: 500px) { margin-bottom: 15px; }
    }

    &__title {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-248);
        font-weight: 700;
        line-height: 0.73em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;

        @media screen and (max-width: 500px) { font-size: 8.6rem; }
        @media screen and (max-width: 320px) { font-size: 8rem; }
    }

    &__bottom-section {
        width: 100%;
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 1300px) { align-items: initial; }
        @media screen and (max-width: 700px) { flex-direction: column; }
    }

    &__picture-container {
        width: var(--picture-width);

        @media screen and (max-width: 1300px) { min-height: 100%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }

    &__picture {
        width: 100%;
        padding-top: 113%;
        position: relative;

        @media screen and (max-width: 1300px) {  height: 100%; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        width: calc(100% - var(--picture-width));
        padding: 65px 0 25px var(--lx-120);

        @media screen and (max-width: 1300px) { padding: 25px 0 25px var(--lx-120); }
        @media screen and (max-width: 1024px) { padding: 0 0 25px var(--lx-120); }
        @media screen and (max-width: 700px) { 
            width: 100%; 
            padding: 50px 0 0 0;
            margin-bottom: 25px;
        }
    }

    &__intro {
        @extend %introduction;
        color: rgb(var(--rgb-01));
        margin-bottom: 40px;

        @media screen and (max-width: 1300px) { margin-bottom: 20px; }
    }

    &__text {
        @extend %paragraph;
        color: rgb(var(--rgb-01));
        margin-top: 25px;

        &:nth-child(2) {
            @media screen and (max-width: 1300px) { margin-bottom: 30px; }
        }
        
        &:nth-child(5) {
            margin-bottom: 50px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            @media screen and (max-width: 1300px) { display: none; }
            @media screen and (max-width: 700px) { display: block; }
        }
    }

    &__second-bottom-section {
        @media screen and (min-width: 1301px) { display: none; }
        @media screen and (max-width: 700px) { display: none; }

        .first-zone__text-block {
            width: 100%; 
            padding: 50px 0 0 0;

            > :first-child { margin-top: 0; }
        }
    }
}