@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.fifth-zone {
    
}


// ----------------
// Fifth Zone
// ----------------
.fifth-zone {
    &__container {
        width: 100%;
        margin: var(--ly-190) 0 var(--ly-230);

        > :last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 500px) { margin: 80px 0; }
    }

    &__content {
        width: 100%;
        padding-right: var(--lx-120);
        margin-bottom: var(--ly-140);
        position: relative;

        @media screen and (max-width: 800px) { padding-right: 0; }

        @media screen and (max-width: 500px) { margin-bottom: 65px; }

        &--reverse {
            padding-right: 0;
            padding-left: var(--lx-120);
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media screen and (max-width: 1100px) { 
                display: block;
                flex-direction: initial;
                align-items: initial;
            }
            @media screen and (max-width: 800px) { padding-left: 0; }
        }
    }

    &__big-text {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-248);
        font-weight: 700;
        line-height: 0.73em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: -1px;

        @media screen and (max-width: 800px) { text-align: center; }
        @media screen and (max-width: 500px) { font-size: 8.6rem; }
        @media screen and (max-width: 320px) { font-size: 8rem; }
    }

    &__image-and-text {
        display: flex;

        @media screen and (max-width: 800px) { flex-direction: column; }
    }

    &__picture-container {
        width: 1000px;

        @media screen and (max-width: 1100px) { width: 100%; }
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 62%;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    &__text-block {
        position: absolute;
        left: calc(1000px - 140px);
        bottom: 0;
        z-index: 5;

        @media screen and (max-width: 1440px) {
            right: calc(var(--lx-120) + var(--lx-80));
            left: initial;
        }
        @media screen and (max-width: 1200px) { right: var(--lx-120); }
        @media screen and (max-width: 800px) { 
            width: 100%;
            padding: 0 var(--lx-120);
            margin-top: -40px;
            position: relative;
            right: initial;
            bottom: initial; 
        }
    }

    &__content--reverse &__text-block {
        right: calc(1000px - 140px);
        left: initial;

        @media screen and (max-width: 1440px) {
            left: calc(var(--lx-120) + var(--lx-80));
            right: initial;
        }
        @media screen and (max-width: 1200px) { left: var(--lx-120); }
        @media screen and (max-width: 800px) { 
            width: 100%;
            padding: 0 var(--lx-120);
            margin-top: -40px;
            position: relative;
            left: initial;
            bottom: initial; 
        }
    }

    &__text {
        max-width: 380px;
        background: rgb(var(--rgb-04));
        padding: var(--ly-100) var(--lx-60);
        @extend %paragraph;
        color: rgb(var(--rgb-white));

        strong {
            font-weight: 700;
        }

        @media screen and (max-width: 800px) { 
            width: 100%;
            max-width: 100%; 
        }
    }
}