@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}

//-------------------
// Variables locales
//-------------------
.expertises-section {
    
}


// ----------------
// Expertises Section
// ----------------
.expertises-section {
    
    &__container {
        width: 100%;
        display: flex;

        @media screen and (max-width: 800px) { 
            flex-direction: column; 
        }
    }

    &__left-section {
        width: 50%;
        padding-left: var(--lx-120);
        padding-bottom: var(--ly-100);
        background: rgb(var(--rgb-04));
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 800px) { 
            width: 100%;
            padding-bottom: 45px;
            justify-content: initial;
        }
    }

    &__left-section-top {
        width: 100%;
    }
    
    &__image-slider {
        height: 100%!important;

        .slick-list,
        .slick-track,
        .slick-slide,
        .expertises-section__image-slider-slide,
        .slick-slide > div {
            height: 100%!important;
        }
    }

    &__text-slider,
    &__image-slider {
        width: 100%;
    }

    &__text-slider {
        @media screen and (max-width: 800px) { margin-bottom: 55px; }
        @media screen and (max-width: 700px) { margin-bottom: 45px; }
        @media screen and (max-width: 600px) { margin-bottom: 35px; }

        .slick-list {
            transition: height 600ms var(--in-out-quart);
        }
    }

    .slick-slide div {
        font-size: 0;
    }

    &__text-slider-slide {
        padding: var(--ly-130) var(--lx-120) 0 0;

        @media screen and (max-width: 500px) { padding: 55px var(--lx-120) 0 0; }
    }

    &__number {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-150);
        font-weight: 700;
        line-height: 0.73em;
        color: rgba(var(--rgb-black), 0.3);
        position: absolute;
        top: 20px;
        right: 35px;

        @media screen and (max-width: 500px) { right: calc(var(--lx-120) - 1px); }
    }

    &__surtitle {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 400;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-bottom: 40px;

        @media screen and (max-height: 700px) { margin-bottom: 30px; }
    }

    &__title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-45);
        font-weight: 700;
        line-height: 1.2em;
        color: rgb(var(--rgb-white));
        margin-bottom: 60px;
        margin-left: -1px;

        @media screen and (max-height: 800px) { margin-bottom: 55px; }
        @media screen and (max-height: 700px) { margin-bottom: 35px; }
        @media screen and (max-height: 600px) { margin-bottom: 25px; }
        
        @media screen and (max-width: 500px) { 
            font-size: 2.5rem;
            margin-bottom: 25px; 
        }
    }

    .span-container {
        display: inline-block;
        overflow: hidden;
    }

    .span-text {
        display: inline-block;
        font-size: var(--fz-45);
    }

    .span-space {
        font-family: initial;
        font-size: var(--fz-45);
        line-height: 0;
        display: inline-block;
    }

    &__link {
        padding-right: 25px;
        transition: padding 400ms var(--out-cubic);
        font-family: var(--ff-1); // Roboto
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        letter-spacing: 0.1em;
        text-transform: uppercase;
        position: relative;

        &:hover {
            padding-right: 35px;
        }

        @media screen and (max-width: 500px) { font-size: 1.1rem; }
    }

    &__svg {

        &--arrow {
            width: 14px;
            height: 13px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 1.5px;
            fill: none;
            position: absolute;
            top: 49.5%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    &__slider-nav {
        display: flex;
        margin-top: 80px;
        margin-bottom: 50px;

        @media screen and (max-width: 500px) { 
            margin-top: 30px;
            margin-bottom: 15px; 
        }
    }

    &__slider-nav-link {
        width: 73px;
        height: 73px;
        background: transparent;
        transition: background 300ms;
        border-radius: 50%;
        border: 2px solid rgb(var(--rgb-white));
        position: relative;

        &:first-child {
            margin-right: 25px;

            @media screen and (max-width: 500px) { margin-right: 15px; }
        }

        &:hover {
            background: rgb(var(--rgb-white));
        }

        @media screen and (max-height: 700px) {
            width: 50px;
            height: 50px;
        }
        @media screen and (max-width: 500px) {
            width: 45px;
            height: 45px;
        }
    }

    &__slider-nav-svg {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-white));
        transition: stroke 300ms, transform 400ms var(--out-cubic);
        stroke-width: 2px;
        fill: none;
        transform: translateX(0);
        position: absolute;
        top: 50%;
        left: 50%;
        will-change: transform;

        &--prev {
            transform: translate(-50%,-50%) rotate(180deg);
        }

        &--next {
            transform: translate(-50%,-50%);
        }
    }

    &__slider-nav-link:hover &__slider-nav-svg {
        stroke: rgb(var(--rgb-05));

        &--prev {
            transform: translate(calc(-50% - 10px),-50%) rotate(180deg);
        }

        &--next {
            transform: translate(calc(-50% + 10px), -50%);
        }
    }

    &__btn {
        &:hover {
            border-color: rgb(var(--rgb-05));
            background: rgb(var(--rgb-05));
        }
    }

    &__right-section {
        width: 50%;

        @media screen and (max-width: 800px) { width: 100%; }
    }

    &__picture {
        width: 100%;
        height: 100%;
        padding-top: 103%;
        position: relative; 
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

// Animation du titre
.expertises-section {
    .span-text,
    .span-space {
        transform: translateY(100%);
        transition-property: transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-cubic);

        &[data-fire] {
            transform: none;
        }
    }

    .addAnimation {
        .span-text,
        .span-space {
            transform: none;
        }
    }

    .removeAnimation {
        .span-text,
        .span-space {
            transform: translateY(100%);
        }
    }
}

.js-scrollfire-delay-enabled .expertises-section {
    .span-text,
    .span-space {
        transition-delay: 400ms;
    }

    .addAnimation {
        .span-text,
        .span-space {
            transition-delay: 200ms;
        }
    }
}