@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    --realisations-list-gap: 35px;

    @media screen and (max-width: 1400px) { --realisations-list-gap: 30px; }
    @media screen and (max-width: 1300px) { --realisations-list-gap: 25px; }
    @media screen and (max-width: 1200px) { --realisations-list-gap: 20px; }
    @media screen and (max-width: 950px)  { --realisations-list-gap: 10px; }
    @media screen and (max-width: 500px)  { --realisations-list-gap: 0px; }
}

//-------------------
// Variables locales
//-------------------
.realisations-list {
    
}


// ----------------
// realisations List
// ----------------
.realisations-list {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: var(--ly-120);
        background: rgb(var(--rgb-01));
        position: absolute;
        top: 0;
        left: 0;
    }

    &__container {
        margin-bottom: 30px;
        padding: 0 var(--lx-120);
        position: relative;
        z-index: 5;
    }

    &__content {
        width: calc(100% + (var(--realisations-list-gap) * 2));
        margin-left: calc(0px - var(--realisations-list-gap));
        margin-right: calc(0px - var(--realisations-list-gap));
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}