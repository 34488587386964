.rubric {
    background: rgb(var(--rgb-white));
    position: relative;

    &:not(&:last-child) {
        margin-bottom: 10px;
    }

    &__header {
        display: flex;
        position: relative;
        min-height: 145px;
        padding: 20px calc(var(--lx-80) + 95px) 20px var(--lx-80);
        cursor: pointer;

        @media screen and (max-width: 1024px) { min-height: 125px; }

        &--with-img {
            padding: 0 175px 0 0;

            .rubric__text {
                padding: 20px 0;
            }

            @media screen and (max-width: 1024px) { padding: 0 135px 0 0; }
            @media screen and (max-width: 768px) { padding: 0 115px 0 0; }
            @media screen and (max-width: 600px) { 
                padding: 20px calc(var(--lx-80) + 95px) 20px var(--lx-80);

                .rubric__text {
                    padding: 0;
                }
            }
        }
    }

    &__picture {
        align-self: flex-start;
        flex-shrink: 0;
        width: 145px;
        height: 145px;
        margin-right: 55px;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            width: 125px;
            height: 125px;
        }
        @media screen and (max-width: 600px) { display: none; }
    }

    &__img {
        transition: transform 300ms;
    }

    &__header:hover &__img {
        transform: scale(1.1);
    }

    &__text {
        align-self: center;
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__subtitle {
        display: block;
        margin-top: 10px;
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.4em;
        color: rgb(var(--rgb-06));
        transition: color 300ms;
    }

    &__header:hover &__subtitle {
        color: rgb(var(--rgb-04));
    }

    &.js-accordion-opened &__subtitle {
        color: rgb(var(--rgb-04));
    }

    &__title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.2em;
        color: rgb(var(--rgb-06));
        transition: color 300ms;
    }

    &__header:hover &__title {
        color: rgb(var(--rgb-04));
    }

    &.js-accordion-opened &__title {
        color: rgb(var(--rgb-04));
    }

    &__circle {
        width: 73px;
        height: 73px;
        border-radius: 50%;
        border: 2px solid rgb(var(--rgb-09));
        background: rgb(var(--rgb-white));
        transition: background 300ms, transform 400ms var(--out-cubic);
        position: absolute;
        top: 50%;
        right: var(--lx-80);
        transform: translateY(-50%);
        will-change: transform;

        @media screen and (max-width: 1024px) {
            width: 50px;
            height: 50px;
        }
        @media screen and (max-width: 768px) {
            width: 40px;
            height: 40px;
        }
    }

    &__svg {
        position: absolute;
        top: 50%;
        left: 50%;

        &--arrow {
            width: 13px;
            height: 14px;
            stroke: rgb(var(--rgb-09));
            transition: stroke 300ms;
            stroke-width: 2px;
            fill: none;
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }

    &__header:hover &__circle {
        background-color: rgb(var(--rgb-09));
    }

    &.js-accordion-opened &__circle {
        background-color: rgb(var(--rgb-09));
        transform: translateY(-50%) rotate(180deg);
    }

    &__header:hover &__svg {
        &--arrow {
            stroke: rgb(var(--rgb-white));
        }
    }

    &.js-accordion-opened &__svg {
        &--arrow {
            stroke: rgb(var(--rgb-white));
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;

        &--with-img {
            .rubric__transition {
                padding-top: 45px;
            }

            @media screen and (max-width: 600px) {
                .rubric__transition {
                    padding-top: 0;
                }
            }
        }
    }

    &__transition {
        padding: 10px var(--lx-80) 0;
        opacity: 0;
        transform: translateY(100px);
        transition-property: opacity, transform;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }
    
    &__transition > :first-child {
        margin-top: 0;
    }

    &__dynamic {

        h3 {
            font-family: var(--ff-1); // Roboto
            font-size: var(--fz-25);
            font-weight: 700;
            line-height: 1.2em;
            color: rgb(var(--rgb-01));
            margin-top: 50px;
            margin-bottom: 25px;
        }

        h4 {
            font-family: var(--ff-1); // Roboto
            font-size: var(--fz-20);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-01));
            margin-top: 50px;
            margin-bottom: 25px;
        }

        p {
            font-family: var(--ff-1); // Roboto
            font-size: var(--fz-17);
            font-weight: 400;
            line-height: 1.6em;
            color: rgb(var(--rgb-01));
        }

        .table-wrapper {
            table {
                tr {
                    background: rgb(var(--rgb-02));
                
                    &:nth-child(2n) {
                        background: rgb(var(--rgb-08));
                    }
                }
            }
        }

        .emphasis {
            background: rgb(var(--rgb-02));
        }
    }

    &__dynamic,
    &__document,
    &__gallery,
    &__share {
        padding-bottom: var(--ly-70);
    }

    &__document {
        .small-document {
            background: rgb(var(--rgb-02));
        }
    }

    &__document:last-child {
        padding-bottom: var(--ly-90);
    }

    &__share {
        padding-top: 20px;
        padding-bottom: 55px;
    }
}

.rubric {
    &:not(&:last-child) {
        margin-bottom: 30px;

        @media screen and (max-width: 600px) { margin-bottom: 10px; }
    }

    &--special {

        .rubric__header {
            min-height: 330px;
            padding: 0;

            @media screen and (max-width: 1024px) { min-height: 280px; }
            @media screen and (max-width: 768px) { min-height: 250px; }
            @media screen and (max-width: 600px) { 
                min-height: 230px;
                padding: 20px 40px 20px 40px; 
            }
            @media screen and (max-width: 400px) { padding: 20px 25px 20px 25px; }
            @media screen and (max-width: 320px) { padding: 20px; }
        }

        .rubric__text {
            padding: 20px 25px 20px 0;

            @media screen and (max-width: 600px) { padding: 0; }
        }

        .rubric__subtitle {
            margin-top: 15px;
            font-size: var(--fz-20);

            @media screen and (max-width: 500px) { margin-top: 10px; }
        }
        
        .rubric__title {
            font-size: var(--fz-50);
        }

        .rubric__picture {
            width: 330px;
            height: 330px;
            margin-right: 55px;
    
            @media screen and (max-width: 1024px) {
                width: 280px;
                height: 280px;
                margin-right: 50px;
            }
            @media screen and (max-width: 768px) {
                width: 250px;
                height: 250px;
                margin-right: 30px;
            }
            @media screen and (max-width: 600px) { display: none; }
        }

        .rubric__link {
            padding-right: 30px;
            margin-top: 55px;
            transition: padding 400ms var(--out-cubic);
            font-family: var(--ff-1); // Roboto
            font-size: 1.3rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-01));
            transition: color 300ms;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            cursor: pointer;
            display: inline-block;
            position: relative;

            @media screen and (max-width: 1024px) { margin-top: 35px; }
        }

        .rubric__header:hover .rubric__link {
            color: rgb(var(--rgb-04));
        }

        &.js-accordion-opened .rubric__link {
            color: rgb(var(--rgb-04));
        }
    
        .rubric__svg {
    
            &--arrow {
                width: 14px;
                height: 13px;
                stroke: rgb(var(--rgb-05));
                stroke-width: 1.5px;
                fill: none;
                position: absolute;
                top: 49.5%;
                right: 0;
                transform: translateY(-50%) rotate(90deg);
            }
        }

        .rubric__header:hover .rubric__svg {
            &--arrow {
                stroke: rgb(var(--rgb-05));
            }
        }

        &.js-accordion-opened .rubric__svg {
            &--arrow {
                stroke: rgb(var(--rgb-05));
            }
        }

        .rubric__transition {
            padding-top: 45px;

            @media screen and (max-width: 600px) { padding-top: 0; }
        }
    }
}