:root {
    --text-field__color: rgb(var(--rgb-01));
    --text-field__color-placeholder: transparent;
    --text-field__font-size: 1.5rem;

    --text-field__background: transparent;
}

.text-field {
    position: relative;

    &__input {
        width: 100%;
        padding: 8px 0;
        margin-bottom: 1px;
        font-family: var(--font__main);
        font-size: var(--text-field__font-size);
        line-height: 1.6em;
        color: var(--text-field__color);
        border: 0;
        border-bottom: 1px solid rgb(var(--rgb-01));
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        background-color: var(--text-field__background);
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-appearance: none;

        &:focus {
            border-bottom: 2px solid rgb(var(--rgb-04));
            margin-bottom: 0px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
        }


        // Cacher l'icone de calendrier pour les champs "date" et l'icone d'horloge pour les champs "time"
        &[type="time"],
        &[type="date"] {
            &::-webkit-calendar-picker-indicator {
                display: none;
            }

            &:in-range::-webkit-datetime-edit-year-field,
            &:in-range::-webkit-datetime-edit-month-field,
            &:in-range::-webkit-datetime-edit-day-field,
            &:in-range::-webkit-datetime-edit-text {
                color: transparent;
            }
        }
    }

    // Styles spécifiques au Textarea
    textarea {
        outline: none;
        resize: none;
        overflow: hidden;
        height: 43px;
        
        &[style] { // Éviter le skip quand le textarea a une hauteur donnée par la fonction "textareaHeight"
            margin-bottom: 0;
        }
    }

    &__error {
        position: absolute;
        top: 100%;
        left: 0;
    }

    // Style pour erreurs
    &__input.error {
        border-color: rgb(var(--rgb-9));
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--text-field__color-placeholder); }
    ::-moz-placeholder { color: var(--text-field__color-placeholder); }
    :-ms-input-placeholder {  color: var(--text-field__color-placeholder); }
    :-moz-placeholder { color: var(--text-field__color-placeholder); }

    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus, 
    :-webkit-autofill:active {
        font-size: var(--text-field__font-size) !important;
        margin-bottom: 1px !important;
        border-bottom: 1px solid #b2b2b2 !important;
        -webkit-box-shadow: 0 0 0 100px #fff inset !important;
        -webkit-text-fill-color: var(--text-field__color);
    }

    :-webkit-autofill::first-line {
        font-family: var(--font__main);
        font-size: var(--text-field__font-size);
    }
}