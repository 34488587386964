@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}


//-------------------
// Variables locales
//-------------------
.third-zone {
    --picture-width: 630px;

    @media screen and (max-width: 1300px) { --picture-width: 600px; }
    @media screen and (max-width: 1200px) { --picture-width: 550px; }
    @media screen and (max-width: 1300px) { --picture-width: 500px; }
    @media screen and (max-width: 1000px) { --picture-width: 400px; }
    @media screen and (max-width: 900px) { --picture-width: 360px; }
    @media screen and (max-width: 800px) { --picture-width: 320px; }
}


// ----------------
// Third Zone
// ----------------
.third-zone {
    
    &__container {
        width: 100%;
        background: rgb(var(--rgb-01));
        padding-top: var(--ly-170);

        @media screen and (max-width: 568px) { padding-top: 30px; }
    }

    &__top-section {
        width: 100%;
        padding: 0 var(--lx-120);
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 1300px) { align-items: initial; }
        @media screen and (max-width: 700px) { flex-direction: column; }
    }

    &__text-block {
        width: calc(100% - var(--picture-width));
        padding-top: 150px;
        padding-right: var(--lx-170);
        padding-bottom: 25px;

        @media screen and (max-width: 1400px) { padding-top: 100px; }
        @media screen and (max-width: 1300px) { padding-top: 50px; }
        @media screen and (max-width: 1200px) { padding-top: 0; }
        @media screen and (max-width: 700px) { 
            width: 100%; 
            padding-bottom: 0;
            order: 2;
        }
    }

    &__intro {
        @extend %introduction;
        color: rgb(var(--rgb-white));
        margin-bottom: 40px;

        @media screen and (max-width: 1300px) { margin-bottom: 20px; }
    }

    &__text {
        @extend %paragraph;
        color: rgb(var(--rgb-white));
        margin-top: 25px;

        &:nth-child(5) {
            margin-bottom: 50px;
        }

        &:nth-child(4),
        &:nth-child(5) {
            @media screen and (max-width: 1300px) { display: none; }
            @media screen and (max-width: 700px) { display: block; }
        }
    }

    &__picture-container {
        width: var(--picture-width);
        position: relative;

        @media screen and (max-width: 1300px) { min-height: 100%; }
        @media screen and (max-width: 700px) { 
            width: 100%;
            margin-bottom: 50px;
            order: 1; 
        }
    }

    &__picture {
        width: 100%;
        padding-top: 113%;
        position: relative;

        @media screen and (max-width: 1300px) {  height: 100%; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__credit {
        font-family: var(--ff-1); // Roboto
        font-size: 1.4rem;
        font-weight: 400;
        color: rgb(var(--rgb-white));
        background: rgba(var(--rgb-01), 0.5);
        padding: 13px 25px;
        position: absolute;
        right: 0;
        bottom: 0;

        @media screen and (max-width: 768px) { padding: 15px; }
    }

    &__second-top-section {
        padding: 50px var(--lx-120) 70px;

        .third-zone__text-block {
            width: 100%;
            padding: 0;

            > :first-child { margin-top: 0; }
        }

        @media screen and (min-width: 1301px) { display: none; }
        @media screen and (max-width: 700px) { display: none; }
    }

    &__bottom-section {
        width: 100%;
        height: 300px;
        background: rgb(var(--rgb-04));
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1300px) { height: 250px; }
        @media screen and (max-width: 900px) { height: 200px; }
        @media screen and (max-width: 700px) { margin-top: 35px; }
        @media screen and (max-width: 600px) { height: 150px; }
        @media screen and (max-width: 500px) { height: 100px; }
        @media screen and (max-width: 400px) { height: 90px; }
    }

    &__bottom-section-title {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-135);
        font-weight: 600;
        line-height: 0.73em;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;

        @media screen and (max-width: 600px) { font-size: 5.6em; }
        @media screen and (max-width: 500px) { font-size: 4.6em; }
        @media screen and (max-width: 400px) { font-size: 3.5rem; }
    }
}