// ---------------------------------------------------------------------------
// Tous les effets de scrollfire du site
// ---------------------------------------------------------------------------
@use '../abstract/scrollfire-effects';
@use '../abstract/scrollfire-delays';


// ---------------------------------------------------------------------------
// Header
// ---------------------------------------------------------------------------
.header {
    &__logo-container,
    &__buttons-container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.js-scrollfire-delay-enabled .header {
    &__logo-container,
    &__buttons-container {
        transition-delay: 400ms;
    }
}


// ---------------------------------------------------------------------------
// Footer
// ---------------------------------------------------------------------------
.footer {
    &__top-section {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Alerte
// ---------------------------------------------------------------------------
.alert {
    &__container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Fil d'arianne
// ---------------------------------------------------------------------------
.breadcrumb {
    &__list,
    &__share-container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Top de page
// ---------------------------------------------------------------------------
.page-title {
    &__picture-container,
    &__text-block,
    &__btn-container {
        @extend .fade-up; 

        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.page-title-contact {
    &__text-block,
    &__picture-container,
    &__share-container,
    &__btn-container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Zone dynamique
// ---------------------------------------------------------------------------
.main {
    &__content {
        @extend .fade-up; 

        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Vitrine
// ---------------------------------------------------------------------------
.showcase {
    &__picture-container {
        @extend .fade-up; 

        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.showcase-white {
    &__picture-container {
        @extend .fade-up; 

        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Petite vitrine
// ---------------------------------------------------------------------------
.small-showcase {
    &__link {
        @extend .fade-up; 

        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Pagination
// ---------------------------------------------------------------------------
.pagination {
    &__content {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.pagination-variant {
    @extend .fade-up; 
    
    &[data-fire] {
        @extend .fade-up--fire;
    }
}



// ---------------------------------------------------------------------------
// Page | Accueil
// ---------------------------------------------------------------------------
.home-banner {
    &__image-slider,
    &__dots,
    &__side-btn-container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.js-scrollfire-delay-enabled .home-banner {
    &__dots,
    &__side-btn-container {
        transition-delay: 400ms;
    }
}

.activities-section {
    &__big-text,
    &__left-section,
    &__right-section {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.expertises-section {
    &__btn {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.realisations-section {
    &__big-text,
    &__left-section,
    &__right-section {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Page | À propos
// ---------------------------------------------------------------------------
.first-zone {
    &__top-section,
    &__bottom-section {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.second-zone {
    &__slider-nav,
    &__slider {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.third-zone {
    &__top-section,
    &__bottom-section {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.fourth-zone {
    &__text-block {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}

.fifth-zone {
    &__content {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Page | Actualités - Liste
// ---------------------------------------------------------------------------
.news-list {
    &__container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}



// ---------------------------------------------------------------------------
// Page | Actualités - Détail
// ---------------------------------------------------------------------------
.similar-news {
    @extend .fade-up; 
    
    &[data-fire] {
        @extend .fade-up--fire;
    }
}

// ---------------------------------------------------------------------------
// Page | Liste d'expertises
// ---------------------------------------------------------------------------
.expertises-list {
    &__content {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Page | Liste des réalisations
// ---------------------------------------------------------------------------
.realisations-list {
    &__content {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire; 
        }
    }
}


// ---------------------------------------------------------------------------
// Page | Liste des secteurs d'activité
// ---------------------------------------------------------------------------
.sectors-list {
    &__content {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}


// ---------------------------------------------------------------------------
// Pages d'erreur (404 et 500)
// ---------------------------------------------------------------------------
.errors {
    &__container {
        @extend .fade-up; 
    
        &[data-fire] {
            @extend .fade-up--fire;
        }
    }
}