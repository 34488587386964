/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract" as *;

:root {

    // Générer les variables CSS des font-families
    @include fontFamilies();

    // Générer les variables CSS des font-sizes
    @include fontSizes();

    // Générer les variables CSS des couleurs
    @include colors();

    // Générer les variables CSS des espacements
    @include spacings();
    
    // Générer les variables CSS du easing 
    @include easings("--in-out-quart", "--out-cubic");

    --paragraph-line-height: 1.6em;
}