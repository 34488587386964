.similar-news {
    margin-bottom: var(--ly-160);

    &__container {
        padding: {
            right: var(--lx-120);
            left: var(--lx-120);
        };
    }

    &__top-section {
        width: 100%;
        margin-bottom: var(--ly-90);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: initial;
        }
    }

    &__title {
        font-family: var(--ff-2); // Roboto
        font-size: var(--fz-70);
        font-weight: 600;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: rgb(var(--rgb-01));
    }

    &__button {
        @media screen and (max-width: 768px) { margin-top: 30px; }
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 20px;

            @media screen and (max-width: 700px) { margin-bottom: 50px; }
        }
    }
}