:root {
    --calendar__background: rgb(var(--rgb-white));
    --calendar__header-color: rgb(var(--rgb-white));
    --calendar__header-background: rgb(var(--rgb-01));

    --calendar__day-of-the-week-color: rgb(var(--rgb-01)); // Couleur des lettres des jours de la semaine ex: D,L,M,M,J,V,S

    --calendar__square-size: 37px;

    @media screen and (max-width: 1024px) {
        --calendar__square-size: 34px;
    }


    // Dates des mois précédants ou suivants --------------------
    --calendar__other-month-date-color: rgba(var(--rgb-01), 0.5);
    --calendar__other-month-date-dot-color: rgba(var(--rgb-01), 0.5);


    // Date avec événement --------------------------------------
    --calendar__date-color: rgb(var(--rgb-01));
    --calendar__date-dot-color: rgb(var(--rgb-01));

    --calendar__date-color-hover: rgb(var(--rgb-white));
    --calendar__date-background-hover: rgb(var(--rgb-01));
    --calendar__date-dot-color-hover: rgb(var(--rgb-white));


    // Date d'aujourd'hui ---------------------------------------
    --calendar__today-background: rgb(var(--rgb-02));
    --calendar__today-color: rgb(var(--rgb-04));
    --calendar__today-dot-color: rgb(var(--rgb-04));

    --calendar__today-background-hover: rgb(var(--rgb-01));
    --calendar__today-color-hover: rgb(var(--rgb-white));
    --calendar__today-dot-color-hover:rgb(var(--rgb-white));


    // Date sélectionnée ----------------------------------------
    --calendar__active-color: rgb(var(--rgb-white));
    --calendar__active-background: rgb(var(--rgb-01));
    --calendar__active-dot-color: rgb(var(--rgb-01));

    --calendar__active-color-hover: rgb(var(--rgb-white));
    --calendar__active-background-hover: rgb(var(--rgb-01));
    --calendar__active-dot-color-hover: rgb(var(--rgb-01));
}

// Calendrier normal
.calendar {
    position: relative;
    padding: 0 34px;
    background: var(--calendar__background);
}

// Calendrier en popup dans champ date
.datepickerWrapper.date {
    padding-bottom: 0;
    background: var(--calendar__background);

    .day::after {
        display: none !important;
    }
}

.calendar,
.datepickerWrapper.date {
    &::before {
        content: "";
        position: absolute;
        height: 70px;
        top: 0;
        left: 0;
        right: 0;
        background: var(--calendar__header-background);
    }
}

.datepicker {
    position: relative;
    width: 100%;
    padding-top: 27px;
    padding-bottom: 30px;
    direction: ltr;

    table {
        width: 100%;
        margin: 0;
        user-select: none;
        table-layout: fixed;
        word-wrap: break-word;
    }

    // HEAD ----------
    .datepicker-switch,
    .prev,
    .next {
        transition: opacity 300ms;
    }

    .prev,
    .next {
        width: 25px;
        font-size: 0;
        background-image: url("../medias/images/icons/pointer-white.svg");
        background-repeat: no-repeat;
        background-size: 15px 12px;
        cursor: pointer;

        &.disabled {
            visibility: hidden;
        }
    }

    .datepicker-switch {
        width: calc(100% - 50px);
        font-size: 1.7rem;
        line-height: 1em;
        font-weight: bold;
        color: var(--calendar__header-color);
    }

    .prev {
        transform: rotate(90deg);
        background-position: 50% 50%;
        transition: background 300ms var(--out-cubic);

        &:hover {
            background-position: 50% 60%;
        }
    }

    .next {
        transform: rotate(-90deg);
        background-position: 50% 50%;
        transition: background 300ms var(--out-cubic);

        &:hover {
            background-position: 50% 60%;
        }
    }

    // ---------------

    // DAYS ----------
    .dow {
        width: 38px;
        padding-top: 60px;
        padding-bottom: 10px;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1em;
        color: var(--calendar__day-of-the-week-color);
        text-align: center;
        cursor: default;
    }

    // ---------------

    // DATES ---------
    .day {
        position: relative;
        width: var(--calendar__square-size);
        height: var(--calendar__square-size);
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1em;
        color: var(--calendar__date-color);
        transition: color 300ms;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;
        transition: color 0.3s;

        &::before {
            content: "";
            position: absolute;
            width: var(--calendar__square-size);
            height: 100%;
            top: 0;
            left: 50%;
            background: transparent;
            border-radius: 3px;
            transform: translate(-50%, 0);
            transition: background 0.3s;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            top: 8px;
            left: calc(50% + 9px);
            background: transparent;
            border-radius: 50%;
            transition: background 0.3s;
            z-index: 1;
        }

        &.disabled,
        &.disabled:hover {
            background: none;
            cursor: default;

            &::after {
                display: none;
            }
        }

        &:not(.disabled) {

            &::after {
                background: var(--calendar__date-dot-color);
            }

            &:hover {
                color: var(--calendar__date-color-hover);
                
                &::before {
                    background: var(--calendar__date-background-hover);
                }
            }
        }
    }

    .day.today {
        color: var(--calendar__today-color);

        &::before {
            background: var(--calendar__today-background);
        }

        &::after {
            background: var(--calendar__today-dot-color);
        }

        &:hover:not(.disabled) {
            color: var(--calendar__today-color-hover);

            &::before {
                background: var(--calendar__today-background-hover);
            }

            &::after {
                background: var(--calendar__today-dot-color-hover);
            }
        }
    }

    .day.active {
        color: var(--calendar__active-color);

        &::before {
            background: var(--calendar__active-background);
        }

        &::after {
            background: var(--calendar__active-dot-color);
        }

        &:hover {
            color: var(--calendar__active-color-hover);

            &::before {
                background: var(--calendar__active-background-hover);
            }

            &::after {
                background: var(--calendar__active-dot-color-hover);
            }
        }
    }

    .old,
    .new {
        color: var(--calendar__other-month-date-color) !important;

        &::before {
            background: transparent !important;
        }

        &::after {
            background: var(--calendar__other-month-date-dot-color) !important;
        }
    }

}
