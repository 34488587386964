:root{
    --form__grid-gap-x: 80px;
    --form__grid-gap-y: 50px;
    --form__success-color: var(--color-grayscale-black);

    @media screen and (max-width: 900px) {  --form__grid-gap-x: 0; }
}

.form {
    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--form__grid-gap-y) * -1);
        margin-right: calc(var(--form__grid-gap-x) / -2);
        margin-left: calc(var(--form__grid-gap-x) / -2);
    }

    &__column{
        padding-left: calc(var(--form__grid-gap-x) / 2);
        padding-right: calc(var(--form__grid-gap-x) / 2);
        margin-top: var(--form__grid-gap-y);
        width: 100%;

        &:empty {
            margin-top: 0;
        }

        &--file + &--file {
            @media screen and (max-width: 500px) { margin-top: 25px; }
        }
    }

    &__column--half {
        width: 50%;

        @media screen and (max-width: 900px) { width: 100%; }
    }

    .oc-loading {
        pointer-events: none;
    }
}

.alert.alert-success {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 1.3;
    color: var(--form__success-color);

    button {
        display: none;
    }
}

.form-field-error-label {
    position: absolute;
    top: 110%;
    font-size: 13px;
    font-weight: 100;
    color: rgb(var(--rgb-9));
}

.input-calendar {
    .datepickerWrapper {
        position: absolute;
        width: 350px;
        padding: 0 30px 30px;
        top: calc( 100% + 20px );
        left: 0;
        background-color: #fff;
        opacity: 0;
        box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.15);
        visibility: hidden;
        pointer-events: none;
        z-index: 100;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        @media screen and (max-width: 500px) { padding: 0 20px 30px; }
        @media screen and (max-width: 425px) { width: 100%; }

        &.show {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transition: opacity 300ms;
        }
    }
}
