.document {
    background: rgb(var(--rgb-white));
    position: relative;

    &:not(&:last-child) {
        margin-bottom: 15px;
    }

    &__link {
        display: flex;
        position: relative;
        min-height: 145px;
        padding: 20px 175px 20px 80px;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            min-height: 125px;
            padding: 20px 135px 20px 60px;
        }
        @media screen and (max-width: 768px) { padding: 20px 115px 20px 40px; }
        @media screen and (max-width: 400px) { padding: 20px 80px 20px 25px; }
        @media screen and (max-width: 320px) { padding: 20px 75px 20px 20px; }
    }

    &__text {
        align-self: center;
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__title {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.2em;
        color: rgb(var(--rgb-06));
        transition: color 300ms;
    }

    &__link:hover &__title {
        color: rgb(var(--rgb-04));
    }

    &__subtitle {
        display: block;
        margin-top: 10px;
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.4em;
        color: rgb(var(--rgb-06));
        transition: color 300ms;
    }

    &__link:hover &__subtitle {
        color: rgb(var(--rgb-04));
    }

    &__circle {
        width: 73px;
        height: 73px;
        border-radius: 50%;
        border: 2px solid rgb(var(--rgb-09));
        background: rgb(var(--rgb-white));
        transition: background 300ms;
        position: absolute;
        top: 50%;
        right: 80px;
        transform: translateY(-50%);
        will-change: transform;

        @media screen and (max-width: 1024px) {
            width: 50px;
            height: 50px;
            right: 60px;
        }
        @media screen and (max-width: 768px) {
            width: 40px;
            height: 40px;
            right: 40px;
        }
        @media screen and (max-width: 400px) { right: 25px; }
        @media screen and (max-width: 320px) { right: 20px; }
    }

    &__link:hover &__circle {
        background-color: rgb(var(--rgb-09));
    }

    &__svg {
        position: absolute;
        top: 50%;
        left: 50%;

        &--arrow {
            width: 13px;
            height: 14px;
            stroke: rgb(var(--rgb-09));
            transition: stroke 300ms;
            stroke-width: 2px;
            fill: none;
            transform: translate(-50%,-50%);
        }
    }

    &__link:hover &__svg {
        &--arrow {
            stroke: rgb(var(--rgb-white));
        }
    }
}