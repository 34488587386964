@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    --alert-mobile-height: 42px;
}


//-------------------
// Variables locales
//-------------------
.alert,
.alert-mobile {
    --left-section-width: 75px;

    @media screen and (max-width: 900px) { --left-section-width: 50px; }

    --right-section-width: 50px;
}


// ----------------
// Alert
// ----------------
.alert {
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
    pointer-events: none;
    position: absolute;
    top: calc(var(--header-height) + 30px);
    right: 40px;
    z-index: 20;

    @media screen and (max-width: 900px) { display: none; }
    
    &__container {
        max-width: 336px;
        background: rgb(var(--rgb-12));
        display: flex;
        position: relative;
    }

    &__left-section {
        width: var(--left-section-width);
        padding: 30px 0;
        background: rgb(var(--rgb-13));
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__circle {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background: rgb(var(--rgb-12));
        transition: background 300ms;
        position: relative;
    }

    &__container:hover &__circle {
        background: rgb(var(--rgb-white));
    }

    &__svg {
        
        &--lightning {
            width: 16px;
            height: 24px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
            transition: stroke 300ms;
            fill: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    &__container:hover &__svg {
        &--lightning {
            stroke: rgb(var(--rgb-13));
        }
    }

    &__right-section {
        width: calc(100% - var(--left-section-width));
        padding: 30px 60px 30px 30px;
    }

    &__text {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }

    &__close {
        width: 40px;
        height: 40px;
        opacity: 1;
        transition: opacity 300ms;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 5;

        &::before,
        &::after {
            content: "";
            width: 18px;
            height: 1px;
            background: rgb(var(--rgb-white));
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &::before {
            transform: translate(-50%,-50%) rotate(-45deg);
        }

        &::after {
            transform: translate(-50%,-50%) rotate(45deg);
        }

        &:hover {
            opacity: 0.5;
        }
    }
}

.alert-mobile {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 300ms, visibility 300ms, top 400ms var(--out-cubic);
    position: absolute;
    top: calc(var(--alert-mobile-height) * -1);
    left: 0;
    display: none;

    @media screen and (max-width: 900px) { display: block; }

    &__container {
        width: 100%;
        height: var(--alert-mobile-height);
        background: rgb(var(--rgb-12));
        display: flex;
    }

    &__left-section {
        width: var(--left-section-width);
        height: 100%;
        background: rgb(var(--rgb-13));
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__circle {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: rgb(var(--rgb-12));
        position: relative;
    }

    &__svg {
        
        &--lightning {
            width: 9px;
            height: 12px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
            fill: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    &__center-section {
        width: calc(100% - var(--left-section-width) - var(--right-section-width));
        height: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__text {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 500px) { font-size: 1.1rem; }
    }

    &__right-section {
        width: var(--right-section-width);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__close {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        transform: translate(-50%,-50%);

        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: rgb(var(--rgb-white));
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &::before {
            transform: translate(-50%,-50%) rotate(-45deg);
        }

        &::after {
            transform: translate(-50%,-50%) rotate(45deg);
        }
    }
}

.show-alerts {
    .alert {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    @media screen and (max-width: 900px) {
        .alert-mobile {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            top: 0;
        }
    
        .header {
            padding-top: var(--alert-mobile-height);
        }
    
        .overlay-menu {
            top: calc(var(--header-height) + var(--alert-mobile-height));
            
            &__container {
                height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--alert-mobile-height));
            }
        }
    
        .home-banner {
            &__container {
                height: calc((var(--vh, 1vh) * 100) - var(--alert-mobile-height));
            }

            @media screen and (max-width: 768px) {
                &__container {
                    height: calc((var(--vh, 1vh) * 100) - var(--alert-mobile-height) - var(--mobile-contact-button-height));
                }
            }
        }
    }
}