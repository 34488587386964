@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    --breacrumb-height: 116px;

    @media screen and (max-height: 700px) { --breacrumb-height: 75px; }
}


//-------------------
// Variables locales
//-------------------
.breadcrumb {
    
}


// ----------------
// Breadcrumb
// ----------------
.breadcrumb {

    &__container {
        width: 100%;
        height: var(--breacrumb-height);
        padding: 50px var(--lx-120);
        background: rgb(var(--rgb-06));
        position: relative;

        @media screen and (max-height: 700px) { padding: 30px var(--lx-120); }
        @media screen and (max-width: 500px) { 
            height: auto;
            padding: 20px var(--lx-120) 40px;
        }
    }

    &__list {
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-14);
        font-weight: 400;
        line-height: 1.2em;
        color: rgb(var(--rgb-white));
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        z-index: 5;
    }

    &__link-item, 
    &__item { 
        margin-right: 20px;
    }

    &__link-item {
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__item {
        margin-left: 20px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__share-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__share {
        position: absolute;
        top: 50%;
        right: calc(var(--lx-120) / 2);
        transform: translate(50%,-50%);
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 700px) { display: none; }
    }

    &__svg {

        &--share {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-white));
            fill: rgb(var(--rgb-white));
            position: relative;
        }
    }
}