@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    --mobile-contact-button-height: 53px;
}


//-------------------
// Variables locales
//-------------------
.home-banner {
    
}


// ----------------
// Home Banner
// ----------------
.home-banner {
    
    &__container {
        width: 100%;
        height: calc((var(--vh, 1vh) * 100));
        transition: height 400ms var(--out-cubic);
        min-height: calc(600px - var(--mobile-contact-button-height));
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 0;
            transition: height 700ms var(--out-cubic);
            background: rgb(var(--rgb-01));
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
        }

        @media screen and (max-width: 768px) {
            height: calc(
                (var(--vh, 1vh) * 100)
                - var(--mobile-contact-button-height)
            );
        }
    }

    &__image-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    &__slider-slide {
        height: 100%;
    }

    &__picture-container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__filter,
    &__gradient {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }

    &__filter {
        background: rgba(var(--rgb-09), 0.5);
        z-index: 5;
    }

    &__gradient {
        background: rgb(var(--rgb-01));
        background: linear-gradient(270deg, rgba(var(--rgb-01),0) 0%, rgba(var(--rgb-01),0.5) 100%);
        z-index: 6;
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__text-slider {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }

    &__content {
        padding: 0 var(--lx-120);
        padding-top: calc(var(--header-height) + 25px);
        padding-bottom: var(--ly-120);
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__title-block {
        max-width: 75%;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 768px) {
            flex: initial;
            margin-bottom: 55px;
        }
        @media screen and (max-width: 600px) { 
            width: 100%;
            max-width: 100%; 
        }
        
        @media screen and (max-height: 600px) { margin-bottom: 35px; }
        @media screen and (max-height: 500px) { margin-bottom: 25px; }
    }

    &__title {
        font-family: var(--ff-2); // Tungsten
        font-size: var(--fz-135);
        font-weight: 600;
        letter-spacing: 0.025em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        margin-bottom: 58px;

        @media screen and (max-width: 900px) { margin-bottom: 48px; }
        
        @media screen and (max-height: 700px) { margin-bottom: 48px; }
        @media screen and (max-height: 600px) { margin-bottom: 38px; }

        @media screen and (max-width: 800px) { margin-bottom: 38px; }
        @media screen and (max-width: 768px) { margin-bottom: 28px; }
        @media screen and (max-width: 600px) { margin-bottom: 18px; }
    }

    .span-container {
        font-size: 0;
        display: inline-flex;
        overflow: hidden;
        will-change: transform;
    }

    .span-text {
        display: inline-block;
        font-size: var(--fz-135);
    }

    .span-space {
        font-family: initial;
        font-size: calc(var(--fz-135) / 1.92);
        display: inline-block;
    }

    .smaller {
        font-size: var(--fz-85);
    }

    &__text-block {
        flex: 1;
        margin-bottom: -4px;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 768px) {
            width: 100%;
            flex: initial;
        }
    }

    &__text {
        max-width: 270px;
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-14);
        font-weight: 400;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__side-btn-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }

    &__side-btn {
        width: var(--lx-120);
        padding-top: 34px;
        font-family: var(--ff-1); // Roboto
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: var(--lx-120);
        color: rgb(var(--rgb-white));
        opacity: 1;
        transition: opacity 300ms;
        position: absolute;
        right: 0;
        bottom: 35px;
        z-index: 5;
        will-change: transform;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 768px) {
            width: 20px;
            height: 21px;
            line-height: 1em;
            right: var(--lx-120);
            bottom: 45px;
        }
        @media screen and (max-width: 400px) {
            width: 14px;
            height: 15px;
        }
    }

    &__side-btn-text {
        @media screen and (max-width: 768px) { display: none; }
    }

    &__svg {
        position: relative;

        &--arrow {
            width: 13px;
            height: 14px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
            fill: none;
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(50%) rotate(-90deg);

            animation-name: bouncing;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;

            @media screen and (max-width: 768px) {
                width: 20px;
                height: 21px;
                right: 0;
                transform: rotate(-90deg) translateX(0%);
            }
            @media screen and (max-width: 400px) {
                width: 14px;
                height: 15px;
            }
        }

        @keyframes bouncing {
            0% { top: 0; }
            50% { top: 10px; }
            100% { top: 0; }
        }

        &--phone {
            width: 17px;
            height: 17px;
            stroke: rgb(var(--rgb-09));
            stroke-width: 1.5px;
            fill: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    &__dots {
        width: var(--lx-120);
        z-index: 5;
        position: absolute;
        top: calc(var(--header-height) + 40px);
        left: 0;

        @media screen and (max-width: 768px) {
            top: initial;
            bottom: 55px;
            left: var(--lx-120);
        }

        ul {
            width: auto;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: auto;

            @media screen and (max-width: 768px) {
                flex-direction: initial;
                left: initial;
                transform: none;
            }
        }

        li {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0;
            margin-bottom: 40px;
            position: relative;

            &::after { 
                content: "";
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: rgba($color: var(--rgb-white), $alpha: 0.5);
                transition: background 300ms var(--out-cubic);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }

            &:last-child {
                margin-bottom: 0;
                margin-right: 0;
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 0;
                margin-right: 40px;
            }

            button { display: none; }
        }

        .slick-active { 
            &::after { background: rgb(var(--rgb-white)); }
        }
    }

    &__contact-button {
        @media screen and (min-width: 768px) { display: none; }
        
        width: 100%;
        height: var(--mobile-contact-button-height);
        background: rgb(var(--rgb-04));
        padding: 0 var(--lx-120);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__contact-text {
        span {
            &:not(:first-child) {
                font-weight: 700;
            }

            font-family: var(--ff-1); // Roboto
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.2em;
            letter-spacing: 0.02em;
            color: rgb(var(--rgb-white));
        }
    }

    &__circle {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: rgb(var(--rgb-white));
        margin-left: 20px;
        display: block;
        position: relative;

        &::before {
            content: "";
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background: rgb(var(--rgb-white));
            transform: scale(1);
            transition: transform 400ms var(--out-cubic);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

// Animation du titre
.home-banner {
    .span-text,
    .span-space {
        transform: translateY(100%);
        transition-property: transform;
        transition-duration: 1300ms;
        transition-timing-function: var(--out-cubic);

        &[data-fire] {
            transform: none;
        }
    }
}

.js-scrollfire-delay-enabled .home-banner {
    .span-text,
    .span-space{
        transition-delay: 400ms;
    }
}