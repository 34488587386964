.main {
    display: flow-root; // Pour inclure le margin top du premier layout__item dans son parent
    position: relative;

    &:last-child {
        margin-bottom: var(--ly-100);
    }

    .main__content {
        padding: {
            right: var(--lx-120);
            left: var(--lx-120);
        };

        &--introduction,
        &--dynamic,
        &--section-header,
        &--form,
        &--small-documents {
            padding: {
                right: calc(var(--lx-120) + var(--lx-80));
                left: calc(var(--lx-120) + var(--lx-80));
            };

            @media screen and (max-width: 500px) { padding: 0 var(--lx-120); }
        }

        &--introduction {
            margin-top: var(--ly-65);
            margin-bottom: var(--ly-65);
        }

        &--section-header {
            margin-top: var(--ly-100);
            margin-bottom: var(--ly-70);

            @media screen and (max-width: 500px) { margin-bottom: 30px; }
        }

        &--dynamic,
        &--small-documents,
        &--gallery,
        &--rubrics,
        &--documents,
        &--form,
        &--calendar {
            margin-top: var(--ly-50);
            margin-bottom: var(--ly-100);
        }
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child:not(.pagination) {
        margin-bottom: 0;
    }

    .white-background {
        &--jobs-form,
        &--contact-form {
            background: rgb(var(--rgb-white));
            padding-top: var(--ly-160);

            > :first-child {
                margin-top: 0;
            }

            > :last-child {
                margin-bottom: 0;
            }
        }

        &--contact-form {
            padding-bottom: var(--ly-100);
        }
    }
}
