/*
|--------------------------------------------------------------------------
| Les Mixins
|--------------------------------------------------------------------------
|
| Les mixins permettent de définir des styles pouvant être réutiliser 
| n'importe où dans dans le SCSS.
|
*/


@use "sass:string";
@use "font-families" as *;
@use "font-sizes" as *;
@use "colors" as *;
@use "easings" as *;
@use "spacings" as *;


/*
|---------------------------------------------------------------------------
| @mixin fontFamilies
|---------------------------------------------------------------------------
| Mixin permettant de convertir en variables CSS les font-families à l'aide
| de la map $font-families ayant un niveau d'imbrication. S'il n'y a qu'une
| seule font-family, le nom ne sera pas pris en compte dans le nom de la
| variable. Exemple: 
| $font-families: (
|     "1": "'font 1', sans-serif",
| );
| Le mixin va retourner la variable CSS --ff.
| S'il y a plus d'une font-family, le nom sera pris en compte. Exemple:
| $font-families: (
|     "1": "'font 1', sans-serif",
|     "2": "'font 2', sans-serif",
| );
| Le mixin va retourner la variable CSS --ff-1, --ff-2.
|
*/

@mixin fontFamilies() {

    // Aller chercher le nombre total de font-families
    $count: 0;
    @each $font-family-name, $font-family-value in $font-families {
        $count: $count + 1;
    }

    // Création de la ou les variables de font-families
    @if $count > 1 {
        @each $font-family-name, $font-family-value in $font-families {
            --ff-#{$font-family-name}: #{$font-family-value};
        }
    } @else {
        @each $font-family-name, $font-family-value in $font-families {
            --ff: #{$font-family-value};
        }
    }

}


/*
|---------------------------------------------------------------------------
| @mixin fontSizes
|---------------------------------------------------------------------------
| Mixin permettant de convertir en variables CSS les font-sizes à l'aide
| de la map $font-sizes ayant deux niveaux d'imbrication. Le premier niveau 
| de la map est le nom de la font-size et le deuxième sont les valeurs de
| la font-size. Exemple:
| $font-sizes: (
|    "70": (
|        "base": 7rem,
|        "1440": 4rem
|    )
| );
| Le mixin va retourner la variable CSS --fz-70 incluant les media queries.
|
*/

@mixin fontSizes() {

    // Parcourir les font-sizes
    @each $font-size, $sizes in $font-sizes {

        // Parcourir les valeurs
        @each $media-query, $size in $sizes {

            // Générer les variables CSS de font-size
            @if $media-query == "base" {
                --fz-#{$font-size}: #{$size};
            } @else {
                @media screen and (max-width: #{$media-query}px) {
                    --fz-#{$font-size}: #{$size};
                }
            }

        }

    }

}


/*
|---------------------------------------------------------------------------
| @mixin colors
|---------------------------------------------------------------------------
|
| Mixin permettant de Convertir en variables CSS les couleurs à l'aide de 
| la map $colors ayant deux niveaux d'imbrication. Le premier niveau de la 
| map est le format, puis le deuxième est le nom. Exemple:
| $colors: (
|     "rgb": (
|         "1": "0, 0, 0"
|     )
| );
| Avec cette map, le mixin va retourner la variable CSS --rgb-1.
|
*/

@mixin colors() {

    // Parcourir les formats de couleurs
    @each $format, $colors-in-map in $colors {

        // Parcourir les couleurs
        @each $color-name, $color-value in $colors-in-map {

            // Création des variables CSS de couleur
            --#{$format}-#{$color-name}: #{$color-value};

        }
    }

}


/*
|---------------------------------------------------------------------------
| @mixin easings
|---------------------------------------------------------------------------
|
| Mixin permettant de convertir en variables CSS le easing à l'aide de la
| map $easings ayant deux niveaux d'imbrication. Le premier niveau de la
| map est la direction du ease et le deuxième est le nom. Ce mixin prend en
| paramètre le ou les noms de variable CSS de easing requis pour le projet.
| Format: easings("--[direction]-[name]");
| Exemple:
| $easings: (
|     "in-out": (
|         "cubic": cubic-bezier(0.65, 0, 0.35, 1)
|     )
| );
| easings("--in-out-cubic");
| Le mixin va retourner la variable CSS --in-out-cubic.
|
*/

@mixin easings($easings-needed...) {

    // Parcourir les paramètres reçus
    @each $easing-needed in $easings-needed {

        // Déclaration des variables
        $direction-needed: "Direction";
        $name-needed: 'Nom';

        // Variables requises de la direction et nom
        @if string.index($easing-needed, "out") == 3 {
            $direction-needed: "out";
            $name-needed: string.slice($easing-needed, 7);
        } @else if string.index($easing-needed, "out") == 6 {
            $direction-needed: "in-out";
            $name-needed: string.slice($easing-needed, 10);
        } @else {
            $direction-needed: "in";
            $name-needed: string.slice($easing-needed, 6);
        }

        // Parcourir les direction
        @each $direction, $names-map in $easings {

            // Est-ce la direction recherchée
            @if $direction == $direction-needed {
            
                // Parcourir les noms
                @each $name, $value in $names-map {

                    // Générer les variables CSS de easing
                    @if $name == $name-needed {
                        --#{$direction}-#{$name}: #{$value};
                    }

                }
                
            }

        }

    }

}


/*
|---------------------------------------------------------------------------
| @mixin spacings
|---------------------------------------------------------------------------
| Mixin permettant de convertir en variables CSS les espacements à l'aide
| de la map $spacings ayant deux niveaux d'imbrication. Le premier niveau 
| de la map est l'orientation de l'espacement et le deuxième sont les
| valeurs de celui-ci. Exemple:
| $spacings: (
|    "lx": (
|        "base": 180px,
|        "1440": 160px
|    )
| );
| Le mixin va retourner la variable CSS --lx-180 incluant les media queries.
|
*/

@mixin spacings() {
    
    // Parcourir les types de la variables $spacings
    @each $type-of-spacing, $type-of-spacing-map in $spacings {

        $x-or-y: "max-width";
        @if $type-of-spacing == "ly" or $type-of-spacing == "sy" {
            $x-or-y: "max-height";
        }

        // Parcourir les valeurs du type recherché
        @each $spacing-value, $spacing-value-map in $type-of-spacing-map {

            // Parcourir les media queries de la valeur recherché
            @each $media-query, $spacing in $spacing-value-map {

                // Création de la variable en fonction des media queries
                @if $media-query == "base" {
                    --#{$type-of-spacing}-#{$spacing-value}: #{$spacing};
                } @else {
                    @media screen and (#{$x-or-y}: #{$media-query}px) {
                        --#{$type-of-spacing}-#{$spacing-value}: #{$spacing};
                    }
                }

            }

        }

    }

}
