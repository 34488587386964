.share {
    font-family: var(--ff-1);
    font-weight: 500;
    font-size: var(--fz-17);
    color: rgb(var(--rgb-01));
    transition: color 300ms;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &:hover {
        color: rgb(var(--rgb-09));
    }

    &__icon {
        width: 12px;
        height: 12px;
        margin-left: 15px;
        fill: rgb(var(--rgb-09));
        transition: fill 300ms;
    }

    &:hover &__icon {
        fill: rgb(var(--rgb-09));
    }
}
