@use "../base/typography";

//--------------------
// Variables globales
//--------------------
:root {
    
}

//-------------------
// Variables locales
//-------------------
.sectors-list {
    
}


// ----------------
// Sectors List
// ----------------
.sectors-list {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: var(--ly-120);
        background: rgb(var(--rgb-01));
        position: absolute;
        top: 0;
        left: 0;
    }

    &__container {
        width: 100%;
        padding: 0 var(--lx-120);
        margin-bottom: var(--ly-160);
    }
}