.small-document {
    background: rgb(var(--rgb-white));
    transition: background 300ms;
    display: flex;
    position: relative;
    min-height: 50px;
    padding: 15px 20px;
    align-items: center;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;

    @media screen and (max-width: 1024px) { padding: 15px 30px; }
    @media screen and (max-width: 768px)  { padding: 15px 20px; }
    @media screen and (max-width: 600px)  { padding: 15px 10px; }

    &:hover {
        background: rgb(var(--rgb-04));
    }

    &__picture {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        margin-right: 30px;
        overflow: hidden;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__img {
        transform: scale(1);
        transition: transform 300ms;
    }

    &:hover &__img {
        transform: scale(1.1);
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__subtitle {
        display: block;
        margin-bottom: 12px;
        font-family: var(--ff-1);
        font-size: var(--fz-14);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-01));
        transition: color 300ms;
    }

    &:hover &__subtitle {
        color: rgb(var(--rgb-white));
    }

    &__title {
        font-family: var(--ff-1);
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.2;
        color: rgb(var(--rgb-01));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-white));
    }

    &__svg-container {
        font-size: 0;
    }

    &__svg {
        width: 18px;
        height: 18px;
        fill: rgb(var(--rgb-05));
        transition-property: stroke, fill;
        transition-duration: 300ms;
        transition-timing-function: linear;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-white));
    }
}